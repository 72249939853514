@media screen and (max-width: 760px) {
  .screen-tournament {
    background-size: cover;
    background-position: bottom -300px center;

    .tournament-title {
      margin: 10px 0 0;
      position: relative;
      font-size: 24px;

      span {
        &::before, &::after {
          display: none;
        }
      }

      .mobile-close {
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        background: url('/assets/icons/close-white.png') no-repeat center;
        top: -10px;
        right: 15px;
      }
    }

    .tournament-items {
      .item {
        padding: 0;
        margin: 40px 0;

        &::before {
          display: none;
        }

        &-wrap {
          padding: 0;
        }
      }
    }
  }
}