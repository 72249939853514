// main: style.less

.inner {
	max-width: 1024px;
	padding: 0px 27px;
	margin: auto;
	width: auto;
	.wrap {
		width: 100%;
	}
}

.lingo-screen {
	padding: 20px 0 90px 0;
	font-size: 16px;
	&-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: -webkit-flex;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
	}
	.picture {
		img {
			max-width: 100%;
		}
	}
	.info {
		&-wrap {
			max-width: 380px;
		}
		.title {
			padding: 10px 0px;
			h1, h2, h3 {
				margin: 0px;
				padding: 5px 0px;
			}
		}
		.content {
			padding: 10px 0px;
			width: auto;
			color: @gray-color;
			line-height: 125%;
		}
	}
}

.lingo-screen_first {
	&--h2 {
		font-size: 20px !important;
		color: @strong-blue-color;
		font-weight: @font-weight-regular !important;
	}
	&--h3 {
		font-size: 36px;
		font-weight: @font-weight-thin;
	}
	.info {
		.title {
			h1 {
				font-size: 20px;
				color: @strong-blue-color;
				font-weight: @font-weight-regular;
			}
			h2 {
				font-size: 36px;
				font-weight: @font-weight-thin;
			}
		}
	}
}

.lingo-screen_second {
	background: #F8F8F8;
	.info {
		.title {
			h2 {
				font-size: 20px;
				color: @strong-blue-color;
				font-weight: @font-weight-regular;
			}
			h3 {
				font-size: 36px;
				font-weight: @font-weight-thin;
			}
		}
	}
}

.lingo-benefits {
	padding: 80px 0px;
	.title {
		text-align: center;
		padding: 10px 0px;
		h2 {
			font-size: 20px;
			color: @strong-blue-color;
			font-weight: @font-weight-regular;
			margin: 0px;
			padding: 5px 0px;
		}
		h3 {
			padding: 15px 0px;
			font-size: 36px;
			font-weight: @font-weight-thin;
			margin: 0px;
			padding: 5px 0px;
		}
	}
	.content {
		padding: 40px 0px 20px;
	}
	.benefits{
		&-items {
			display: flex;
			align-items: center;
			justify-content: space-between;
			display: -webkit-flex;
			-webkit-align-items: center;
			-webkit-justify-content: space-between;
		}
		&-item {
			font-size: 16px;
			&-wrap {
				max-width: 280px;
				width: auto;
			}
			.item {
				position: relative;
				min-height: 100px;
				&:last-of-type {
					&::after {
						height: 100%;
					}
				}
				&::after {
					display: block;
					content: '';
					position: absolute;
					top: 0px;
					width: 65px;
					height: 65px;
					background-position: center;
					background-repeat: no-repeat;
					background-color: #FFFFFF;
					z-index: 9;
				}
				&.joystick {
					&::after {
						background-image: url('/assets/icons/benefits/joystick.jpg');
					}
				}
				&.cup {
					&::after {
						background-image: url('/assets/icons/benefits/cup.jpg');
					}
				}
				&.rating {
					&::after {
						background-image: url('/assets/icons/benefits/rating.jpg');
					}
				}
				&.books-1 {
					&::after {
						background-image: url('/assets/icons/benefits/books-1.jpg');
					}
				}
				&.certificate {
					&::after {
						background-image: url('/assets/icons/benefits/certificate.jpg');
					}
				}
				&.books-2 {
					&::after {
						background-image: url('/assets/icons/benefits/books-2.jpg');
					}
				}
				& + .item {
					margin-top: 35px;
				}
				strong {
					font-size: 16px;
					font-weight: @font-weight-regular;
				}
				p {
					color: @gray-color;
				}
			}
			&_left {
				.benefits-item-wrap-container {
					position: relative;
					&::after {
						position: absolute;
						content: '';
						top: 0px;
						right: 32.5px;
						bottom: 0px;
						width: 2px;
						background: url('/assets/icons/border-dots.png') repeat-y top center;
					}
				}
				.item {
					padding-right: 80px;
					&::after {
						right: 0px;
					}
				}
			}
			&_right {
				.benefits-item-wrap-container {
					position: relative;
					&::before {
						position: absolute;
						content: '';
						top: 0px;
						left: 32.5px;
						bottom: 0px;
						width: 2px;
						background: url('/assets/icons/border-dots.png') repeat-y top center;
					}
				}
				.item {
					padding-left: 80px;
					&::after {
						left: 0px;
					}
				}
			}
			&_center {
				.picture {
					img {
						max-width: 100%;
					}
				}
			}
		}
	}
}

.lingo-block {
	overflow: hidden;
	&-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: -webkit-flex;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
	}
	.picture {
		width: 50%;
	}
	.info {
		width: 50%;
		font-size: 16px;
		&-wrap {
			width: 100%;
		}
		.title {
			padding: 10px 0px;
			h2 {
				font-size: 20px;
				color: @strong-blue-color;
				font-weight: @font-weight-regular;
				margin: 0px;
				padding: 5px 0px;
			}
			h3 {
				padding: 15px 0px;
				font-size: 36px;
				font-weight: @font-weight-thin;
				margin: 0px;
				padding: 5px 0px;
			}
		}
		.content {
			padding: 20px 0px;
			ul {
				list-style: none;
				margin: 0px;
				padding: 0px;
				li {
					list-style: none;
					color: @gray-color;
					line-height: 125%;
				}
			}
		}
	}
}

.lingo-block-with_hand {
	padding: 20px 0px;
	margin-bottom: -160px;
	.picture {
		img {
			width: 225%;
			position: relative;
		}
	}
	.info {
		.content {
			max-width: 380px;
			ul {
				li {
					background-position: left top;
					background-repeat: no-repeat;
					background-image: url('/assets/icons/check-mark-blue.png');
					padding: 5px 0px 5px 40px;
					min-height: 27px;
				}
			}
		}
	}
}

.lingo-block-with_smartphones {
	padding: 0px 0px 60px;
	background: #F8F8F8;
	counter-reset: smcounter;
	position: relative;
	z-index: 9;
	.picture {
		img {
			width: 225%;
			position: relative;
		}
	}
	.info {
		width: 50%;
		.content {
			max-width: 300px;
			ul {
				li {
					position: relative;
					min-height: 100px;
					&::before {
						counter-increment: smcounter;
						content: counter(smcounter);
						display: block;
						position: absolute;
						top: 15px;
						width: 40px;
						height: 40px;
						text-align: center;
						line-height: 40px;
						.border-radius(50%);
						border: 2px solid @blue-color;
						color: @blue-color;
						font-size: 20px;
					}
					strong {
						display: block;
						color: #000000;
						font-size: 16px;
					}
				}
			}
		}
	}
}

.promo-stores {
	padding: 60px 0px 90px;
	background: #5ab3e4;
	background: -moz-linear-gradient(left, #5ab3e4 0%, #87d37c 100%);
	background: -webkit-linear-gradient(left, #5ab3e4 0%,#87d37c 100%);
	background: linear-gradient(to right, #5ab3e4 0%,#87d37c 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ab3e4', endColorstr='#87d37c',GradientType=1 );
	&-blocks {
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: -webkit-flex;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
	}
	.title {
		padding: 10px 0px;
		max-width: 430px;
		width: auto;
		&-first {
			color: #CCCCCC;
			font-size: 20px;
			padding: 5px 0px;
		}
		&-second {
			padding: 5px 0px;
			color: #FFFFFF;
			font-size: 36px;
		}
	}
	.content {
		padding: 10px 0px;
		.store-link {
			display: block;
			width: 200px;
			height: 60px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			cursor: pointer;
			.border-radius(10px);
			.box-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
			margin: 4px;
			& + .store-link {
				margin-top: 24px;
			}
			&.google-play {
				background-image: url('/assets/images/google-play-logo.svg');
			}
			&.app-store {
				background-image: url('/assets/images/app-store-logo.svg');
			}
		}
	}
}

.description-block {
	padding: 70px 0px;
	font-size: 16px;
	.title {
		padding: 5px 0px;
		h2 {
			font-size: 20px;
			color: @strong-blue-color;
			font-weight: @font-weight-regular;
			margin: 0px;
			padding: 5px 0px;
		}
		h3 {
			padding: 15px 0px;
			font-size: 36px;
			font-weight: @font-weight-thin;
			margin: 0px;
			padding: 5px 0px;
		}
	}
	.content {
		padding: 20px 0px;
		line-height: 125%;
		color: @gray-color;
		p {
			line-height: 125%;
			color: @gray-color;
		}
	}
}

.feedback {
	padding: 20px 0 60px 0;
	background: #F8F8F8;
	&-title {
		padding: 15px 0 30px 0;
		text-align: center;
		font-size: 36px;
		font-weight: 300;
		color: #000000;
	}
	&-form {
		padding: 25px 0px;
	}
	&-field {
		padding: 15px 0px;
		&.align-right {
			text-align: right;
		}
	}
	.textfield {
		width: 100%;
		resize: none;
		padding: 19px 26px;
		border: 1px solid @strong-blue-color;
		font-weight: 500;
		outline: none;
		.border-radius(20px);
		background: #FFFFFF;
		appearance: none;
		-webkit-appearance: none;
		&.textareafield {
			height: 160px;
		}
		&::-webkit-input-placeholder { /* Chrome */
			color: #C4C4C4;
			font-weight: 300;
		}
		:-ms-input-placeholder { /* IE 10+ */
			color: #C4C4C4;
			font-weight: 300;
		}
		::-moz-placeholder { /* Firefox 19+ */
			color: #C4C4C4;
			opacity: 1;
			font-weight: 300;
		}
		:-moz-placeholder { /* Firefox 4 - 18 */
			color: #C4C4C4;
			opacity: 1;
			font-weight: 300;
		}
	}
	.buttonfield {
		cursor: pointer;
		color: #FFFFFF;
		width: 100px;
		line-height: 45px;
		text-align: center;
		border: none;
		.border-radius(20px);
		appearance: none;
		-webkit-appearance: none;
		background: #5bb8eb;
		background: -moz-linear-gradient(left, #5bb8eb 0%, #72d8b2 100%);
		background: -webkit-linear-gradient(left, #5bb8eb 0%,#72d8b2 100%);
		background: linear-gradient(to right, #5bb8eb 0%,#72d8b2 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5bb8eb', endColorstr='#72d8b2',GradientType=1 );
	}
	&-links {
		padding: 25px 0px;
		text-align: center;
		color: @gray-color;
		a {
			color: @blue-color;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.footer {
	background: #000000;
	color: #797D84;
	padding: 30px 0px;
	&-top {
		padding: 10px 0px;
		&-blocks {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			display: -webkit-flex;
			-webkit-align-items: flex-start;
			-webkit-justify-content: space-between;
		}
		.title {
			color: @white-color;
			font-size: 36px;
			padding: 15px 0px;
		}
		.content {
			padding: 15px 0px;
		}
		.footer-info {
			.content {
				max-width: 450px;
				width: auto;
			}
		}
		.footer-languages {
			.item {
				width: 50%;
				max-width: 260px;
				display: inline-block;
				line-height: 1.25;
			}
			a {
				color: #797D84;
				text-decoration: none;
			}
		}
	}
	.store-links {
		display: flex;
		align-items: center;
		display: -webkit-flex;
		-webkit-align-items: center;
		&-item {
			display: block;
			width: 180px;
			height: 50px;
			background-size: cover;
			.border-radius(10px);
			background-color: @white-color;
			background-position: center;
			background-repeat: no-repeat;
			position: relative;
			top: 0px;
			.transition(top 100ms linear);
			&:hover {
				top: -2px;
			}
			& + .store-links-item {
				margin: 0 20px;
			}
			&.app-store {
				background-image: url('/assets/images/app-store-logo-white.svg');
			}
			&.google-play {
				background-image: url('/assets/images/google-play-logo.svg');
				border: 1px solid #fff;
				border-radius: 10px;
			}
		}
	}
}

.copyright {
	color: #797D84;
	background: #24272B;
	padding: 30px 0px;
	&-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: -webkit-flex;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
	}
	a {
		color: #797D84;
		text-decoration: none;
	}
}

.articles-block {
	margin: 0 70px 70px;

	.wrap {
		display: flex;
		justify-content: space-around;
	}

	&-title {
		display: block;
		text-align: center;
		margin-bottom: 35px;
		font-size: 36px;
		font-weight: 300;
		color: #1E252B;
		text-decoration: none;
	}

	.articles-card {
		display: block;
		width: 274px;
		color: #000;
		text-decoration: none;
		transition: transform @transition-time linear, box-shadow @transition-time linear;

		&:hover {
			transform: translateY(-3px);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
		}

		&-content {
			display: block;
			padding: 22px 10px 8px 10px
		}

		&-meta {
			display: block;
			margin: 14px 0 18px 0;
			color: #939EAB;
		}

		&-excerpt {
			display: block;
			color: #939EAB;
		}

		&-more {
			display: block;
			color: #5AB3E4;
			font-weight: bold;
			margin: 10px 0;
			text-align: right;

			.icon.icon-more {
				display: inline-block;
				width: 1em;
				margin-bottom: -2px;
				margin-right: 3px;
				fill: #5AB3E4;
			}
		}

		&-th {
			> span {
				display: block;
				height: 200px;
				width: 100%;
				background-size: cover;
				background-position: center;
				background-color: #5AB3E4;
			}
		}

		&-title {
			display: block;
			font-size: 23px;
			line-height: 32px;
			color: #000000;
			font-weight: 400;
		}
	}
}

html[dir="ltr"] {
	.lingo-block-with_hand {
		.picture {
			img {
				left: -600px;
			}
		}
	}
	.lingo-benefits {
		.benefits-item_right {
			text-align: left;
		}
		.benefits-item_left {
			text-align: right;
		}
	}
	.lingo-block-with_smartphones {
		.picture {
			img {
				left: -60px;
			}
		}
		.info {
			.content {
				ul {
					li {
						padding: 15px 0 15px 60px;
						&::before {
							left: 0px;
						}
					}
				}
			}
		}
	}
}

html[dir="rtl"] {
	.lingo-block-with_hand {
		.picture {
			img {
				right: -600px;
				.transform(scale(-1, 1));
			}
		}
	}
	.lingo-benefits {
		.benefits-item_right {
			text-align: right;
		}
		.benefits-item_left {
			text-align: left;
		}
	}
	.lingo-block-with_smartphones {
		.picture {
			img {
				right: -60px;
			}
		}
		.info {
			.content {
				ul {
					li {
						padding: 15px 60px 15px 0px;
						&::before {
							right: 0px;
						}
					}
				}
			}
		}
	}
}
