.screen-rating {
  &-wrap {
    padding: 0 30px;
  }
  .rating-nav {
    &-items {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      color: @white-color;
    }
    &-item {
      display: block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      background: #CCCCCC;
      text-align: center;
      .border-radius(15px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      font-weight: @font-weight-bold;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover:not(.active) {
        background: @white-color;
        color: @dark-blue-color;
      }
      &.active {
        background: @strong-blue-color;
      }
    }
  }
  .rating-list {
    &-table {
      width: 100%;
      border-collapse: collapse;
      color: @white-color;
      display: none;
      &.active {
        display: table;
      }
      tr {
        &.its-me {
          color: @yellow-color;
          font-weight: @font-weight-bold;
        }
        &:first-of-type {
          td {
            line-height: 53px;
            &:nth-child(3) {
              padding: 0 45px;
            }
          }
        }
        & + tr {
          border-top: 1px solid #CCCCCC;
        }
        td {
          text-align: center;
          line-height: 35px;
          &:nth-child(1) {
            width: 40px;
          }
          &:nth-child(2) {
            width: 70px;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 90px;
          }
        }
      }
      .item-flag {
        width: 20px;
        height: 14px;
        margin: 0 auto;
        &-img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
        }
      }
    }
  }
}

html[dir="ltr"] {
  .screen-rating {
    .rating-list-table {
      tr {
        td {
          &:nth-child(3) {
            text-align: left;
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .screen-rating {
    .rating-list-table {
      tr {
        td {
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
  }
}

@import "_screen-rating@media";