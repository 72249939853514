@media screen and (max-width: 760px) {
  .screen-language {
    .flags {
      .item {
        &-title {
          font-size: 14px;
        }
      }
    }
  }
}
