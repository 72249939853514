.screen-lessons {
  min-height: 495px;
  .lessons-nav {
    padding: 0 35px;
    &-items {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      color: @white-color;
    }
    &-item {
      display: block;
      width: 140px;
      height: 40px;
      line-height: 40px;
      background: #CCCCCC;
      text-align: center;
      .border-radius(15px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      font-weight: @font-weight-bold;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      margin: 0 10px;
      text-decoration: none;
      color: @white-color;
      transition: background @transition-time linear, color @transition-time linear;
      &:hover:not(.active) {
        background: @white-color;
        color: @dark-blue-color;
      }
      &.active {
        background: @strong-blue-color;
      }
      & + .lessons-nav-item {
      }
    }
  }
  .lessons-nav-breadcrumb {
    text-align: center;
    position: relative;
    height: 40px;
    .title {
      line-height: 40px;
      color: @white-color;
      font-size: 24px;
      font-weight: @font-weight-bold;
    }
    .navs {
      position: absolute;
      top: 50%;
      left: 45px;
      .transform(translateY(-50%));
      &-wrap {

      }
      &-items {
        display: flex;
        align-items: center;
      }
      &-item {
        display: block;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-image: url('/assets/icons/lessons-navbar.png');
        background-color: @strong-blue-color;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        .border-radius(20px);
        margin: 0 9px;
        cursor: pointer;
        &:hover {
          background-color: @white-color;
        }
        &.back {
          background-position: center;
          background-position: left 9px top 9px;
          &:hover {
            background-position: left 9px top -53px;
          }
        }
        &.home {
          background-position: left -53px top 9px;
          &:hover {
            background-position: left -53px top -53px;
          }
        }
      }
    }
  }
  .lessons-block {
    margin: 30px auto;
    padding: 0 45px;
    position: relative;
  }
  .lessons-items {
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, 200px);
      row-gap: 15px;
      column-gap: 15px;
    }
    .item {
      color: @strong-gray-color;
      background-color: @white-color;
      .border-radius(20px);
      width: 200px;
      padding: 10px 5px;
      font-weight: @font-weight-medium;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      text-decoration: none;
      display: block;
      cursor: pointer;
      .transition(all 200ms linear);
      &:hover {
        .transform(scale(1.025));
      }
      &-wrap {
        position: relative;
      }
      .content {
        display: flex;
        align-items: flex-end;
      }
      .picture {
        margin: 0 5px;
        &-wrap {
          width: 60px;
          height: 60px;
          position: relative;
          overflow: hidden;
          .border-radius(10px);
          background-color: #FFF;
          border: 1px solid rgba(127, 127, 127, 0.1);
          img {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // .transform(translate(-50%, -50%));
            // max-width: 60px;
            // max-height: 60px;
            // width: auto;
            // height: auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
            -o-object-fit: contain;
          }
        }
      }
      .info {
        margin: 0 5px;
        .title {
          padding: 10px 0;
        }
        .progress {
          color: @green-color;
        }
      }
      .status {
        position: relative;
        padding: 0 5px;
        &-wrap {
          width: 100%;
          position: relative;
          background: #EEEEEE;
          height: 10px;
          margin: 10px auto 0;
          .border-radius(20px);
        }
        &-bar {
          position: absolute;
          top: 0px;
          width: 0%;
          height: 10px;
          .border-radius(20px);
          .transition(width 200ms linear);
          background: @green-color;
        }
      }
      .premium {
        display: block;
        position: absolute;
        top: 0px;
        background: @yellow-color;
        color: @blue-color;
        font-size: 10px;
        line-height: 17px;
        height: 17px;
        width: 65px;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        .border-radius(20px);
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .scenarios-block {
    margin: 30px auto;
    width: 410px;
    .scenarios-nav {
      &-items {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        color: @white-color;
      }
      &-item {
        display: block;
        width: 200px;
        height: 40px;
        line-height: 40px;
        background: #CCCCCC;
        text-align: center;
        .border-radius(15px);
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        font-weight: @font-weight-bold;
        font-size: 13px;
        text-transform: uppercase;
        cursor: pointer;
        transition: background @transition-time linear, color @transition-time linear;
        &:hover:not(.active) {
          background: @white-color;
          color: @dark-blue-color;
        }
        &.active {
          background: @strong-blue-color;
        }
      }
    }
    .scenarios-items {
      padding: 30px 0 0;
      &.memorizer {
        display: none;
        // visibility: hidden;
      }
      &-wrap {
        position: relative;
      }
      .scenarios-border {
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        width: 2px;
        // height: 100%;
        background-position: center;
        background-repeat: repeat-y	;
        background-image: url('/assets/icons/dots-vertical.png');
        // &-classic {
        // 	height: 502px;
        // }
        // &-memorizer {
        // 	height: 284px;
        // }
      }
      .scenarios-group {
        &-wrap {
          position: relative;
        }
        & + .scenarios-group {
          padding: 30px 0 0;
        }
      }
      .number {
        display: block;
        position: absolute;
        background: @strong-blue-color;
        color: @white-color;
        text-align: center;
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 30px;
        .border-radius(100%);
        top: 0px;
        left: 0px;
      }
      .items {
        padding: 0 0 0 42px;
      }
      .item {
        display: block;
        width: 100%;
        text-decoration: none;
        color: @strong-gray-color;
        background-color: @white-color;
        padding: 10px 7px;
        .border-radius(20px);
        height: 56px;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        cursor: pointer;
        .transition(all 200ms linear);
        &:hover {
          .transform(scale(1.025));
        }
        & + .item {
          margin: 10px auto 0;
        }
        &-wrap {
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          .premium {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            width: 65px;
            text-align: center;
            line-height: 17px;
            height: 17px;
            text-align: center;
            color: @blue-color;
            background: @yellow-color;
            .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
            .border-radius(20px);
            font-size: 10px;
            text-transform: uppercase;
            font-weight: @font-weight-medium;
          }
        }
        .item-grade {
          margin: 0 7px;
          position: relative;
          display: block;
          width: 42px;
          height: 35px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          &.mark {
            &-no-mark {
              background-image: none;
            }
            &-1 {
              background-image: url('/assets/icons/marks/mark-1.png');
            }
            &-2 {
              background-image: url('/assets/icons/marks/mark-2.png');
            }
            &-3 {
              background-image: url('/assets/icons/marks/mark-3.png');
            }
            &-4 {
              background-image: url('/assets/icons/marks/mark-4.png');
            }
            &-4-plus {
              background-image: url('/assets/icons/marks/mark-4-plus.png');
            }
            &-5 {
              background-image: url('/assets/icons/marks/mark-5.png');
            }
            &-5-plus {
              background-image: url('/assets/icons/marks/mark-5-plus.png');
            }
          }
        }
        .content {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
        }
        .icon {
          width: 36px;
          height: 36px;
          margin: 0 7px;
          background-repeat: no-repeat;
          background-position: center;
          .border-radius(10px);
          &.flashcards {
            background-image: url('/assets/icons/lessons/flashcards.png');
          }
          &.flashcards-closed {
            background-image: url('/assets/icons/lessons/flashcards-closed.png');
          }
          &.true-false {
            background-image: url('/assets/icons/lessons/true-false.png');
          }
          &.correct {
            background-image: url('/assets/icons/lessons/correct.png');
          }
          &.writing {
            background-image: url('/assets/icons/lessons/writing.png');
          }
          &.audio {
            background-image: url('/assets/icons/lessons/audio.png');
          }
          &.brackets {
            background-image: url('/assets/icons/lessons/brackets.png');
          }
        }
        .title {
          font-size: 16px;
          margin: 0 7px;
          span {
            display: block;
            color: #CCCCCC;
            font-size: 13px;
          }
        }
      }
    }
  }
}

html[dir="ltr"] {
  .screen-lessons {
    .lessons-items {
      .item {
        .premium {
          right: 5px;
        }
        .status-bar {
          left: 0;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .screen-lessons {
    .lessons-items {
      .item {
        .premium {
          left: 5px;
        }
        .status-bar {
          right: 0;
        }
      }
    }
  }
}

@import "_screen-lessons@media";

.compact .screen-lessons {
  .lessons-items .item {
    max-width: 200px;
    margin: 8px;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}