@media screen and (max-width: 760px) {
  .screen-playtype {
    position: fixed;

    .label {
      display: block;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: @white-color;
    }

    .items {
      flex-direction: column;

      .item {
        border-radius: 28px;
        width: 100%;
        max-width: 250px;
        height: 100px;
        margin: 14px auto;

        .img-thumb {
          position: absolute;
          bottom: 0;
          width: 80px;
          height: 80px;
          border-bottom-left-radius: 28px;

          &.words {
            background: url('/assets/icons/cards/words.svg') no-repeat center;
          }

          &.cards {
            width: 118px;
            background: url('/assets/icons/cards/flashcards.png') no-repeat center;
          }

          &.phrases {
            background: url('/assets/icons/cards/phrases.svg') no-repeat center;
          }

          &.tournament {
            width: 118px;
            background: url('/assets/icons/cards/tournament.svg') no-repeat center;
          }
        }

        .title {
          transform: none;
          right: 15px;
          top: 10px;
          left: auto;
          font-weight: @font-weight-medium;
          font-size: 22px;
          line-height: 26px;
        }

        .premium {
          top: auto;
          bottom: 10px;
          border-radius: 0;
          font-style: normal;
          font-weight: @font-weight-bold;
          letter-spacing: 0.065em;
          text-transform: uppercase;
          font-size: 8px;
          background: url('/assets/icons/cards/premium-card.svg') no-repeat center;
          box-shadow: none;
        }
      }
    }
  }
}