@media screen and (max-width: 760px) {
  .screen-invitation {
    position: fixed;

    .popup {
      width: 90%;

      .actions {
        &-wrap {
          display: block;
        }
      }

      .button {
        width: 100%;

        & + .button {
          margin: 10px auto 0;
        }
      }
    }
  }
}