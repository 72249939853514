.screen-playtype {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
    padding: 70px 0 0;
    margin: -70px auto 0;
    .border-radius(22px);
  }

  .label {
    display: none;
  }

  &-items {
    width: 100%;
    margin-top: 70px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-justify-content: center;
  }
  .item {
    display: block;
    width: 35%;
    max-width: 225px;
    height: 140px;
    background: @blue-color;
    color: @white-color;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    text-decoration: none;
    position: relative;
    margin: 20px;
    cursor: pointer;
    transition: all 200ms linear;
    &:hover {
      background: #379dd6;
    }
    .premium {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      width: 65px;
      text-align: center;
      line-height: 17px;
      height: 17px;
      color: @blue-color;
      background: @yellow-color;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      .border-radius(20px);
      font-size: 10px;
      text-transform: uppercase;
      font-weight: @font-weight-medium;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translate(-50%, -50%));
      display: block;
      font-size: 18px;
      font-weight: @font-weight-bold;
    }
  }
}

.mobile-screen-playtype {
  display: none;
}

@import "_screen-playtype@media";