@media screen and (max-width: 760px) {
  .screen-articles {
    .articles {
      &-inner {
        display: block;
      }

      &-aside-left {
        max-width: 100%;
        min-width: 90px;
        overflow: hidden;
        //height: 100px; background: red; border-right: 1px solid #000; // debug
      }

      &-aside-right {
        max-width: 100%;
        min-width: 90px;
        overflow: hidden;
        //height: 100px; background: orange; border-left: 1px solid #000; // debug
      }

      &-cards {
        margin: 20px 0;
      }
    }
  }
}