.screen-test-restriction {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // background: rgb(12,100,149);
  // background: -moz-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // background: -webkit-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // background: linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c6495",endColorstr="#5ab3e4",GradientType=1);
  z-index: 9;
  color: @white-color;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  .popup {
    position: absolute;
    top: 135px;
    left: 50%;
    .transform(translateX(-50%));
    width: 330px;
    background: @blue-color;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    z-index: 99;
    text-align: center;
    &-wrap {
      padding: 26px 10px 10px;
    }
    .title {
      padding: 16px;
      color: @yellow-color;
      font-size: 18px;
      font-weight: @font-weight-bold;
    }
    .desc {
      font-size: 16px;
      font-weight: @font-weight-thin;
      line-height: 125%;
    }
    .timer {
      padding: 24px 0;
      font-size: 26px;
      font-weight: @font-weight-medium;
    }
    .submit {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
      display: block;
      text-align: center;
      height: 56px;
      line-height: 56px;
      .border-radius(20px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      font-size: 18px;
      font-weight: @font-weight-medium;
      background: #89D280;
      cursor: pointer;
      color: #FFFFFF;
      .transition(all 200ms linear);
      &:hover {
        background: rgb(128, 204, 119);
      }
    }
  }
}

@import "_screen-test-restriction@media";
