.ad-preview {
  border: 1px dashed #F7584B;
  background: #00C89F;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 5px;

  .title {
    font-weight: bold;
  }

  .size {
    margin-top: 5px;
    color: #F7584B;
  }

  &.playground-navbar-item {
    font-size: 10px;
    padding: 0;
  }

  &.playground-sidebarnav-item {
    display: block;
  }
}

.article-aside-ad1 {
  margin: 0 auto;
  padding: 0 18px 70px 18px;

  &-title {
    color: #000;
    text-align: center;
  }

  &-store-links {
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto;
  }

  &-store-link {
    display: block;
    width: 144px;
    min-width: 144px;
    height: 40px;
    margin: 10px auto;
    background-size: cover;
    border-radius: 10px;
    background-color: @white-color;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &.app-store {
      background-image: url('/assets/images/app-store-logo.svg');
    }
    &.google-play {
      background-image: url('/assets/images/google-play-logo.svg');
      //border: 1px solid #fff;
      border-radius: 10px;
    }
  }
  &-action {
    text-align: center;
  }
}