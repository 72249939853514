@media screen and (max-width: 760px) {
  .screen-register-sign-in {
    .reg-steps-wrap {
      background: none;
    }
    .reg-steps-items {
      display: block;
    }
    .reg-steps-item {
      display: none;
      margin: auto;
      &.active {
        display: block;
      }
    }
    .auth-buttons {
      display: block;
      max-width: 100%;
      .button-holder {
        width: 100%;
        margin: 10px auto;
      }
    }
    .auth-social {
      .title {
        margin: 40px auto 25px;
      }
      .item {
        width: 90px;
        height: 50px;
      }
    }
    .recover-form {
      margin: 0px auto;
    }
  }
}