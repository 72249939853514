.screen-exit {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.65);
  z-index: 999;
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .popup {
    position: absolute;
    top: 135px;
    left: 50%;
    .transform(translateX(-50%));
    width: 330px;
    text-align: center;
    color: @white-color;
    font-size: 18px;
    font-weight: @font-weight-medium;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    background: @blue-color;
    &-wrap {
      padding: 10px;
    }
    .content {
      .desc {
        padding: 15px 0;
        line-height: 125%;
        max-width: 180px;
        width: 100%;
        margin: auto;
      }
      .actions {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -wwebkit-justify-content: space-between;
      }
      .action {
        cursor: pointer;
        display: block;
        width: 150px;
        line-height: 56px;
        height: 56px;
        .border-radius(20px);
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        color: #FFFFFF;
        text-decoration: none;
        &.accept {
          background: #89D280;
          transition: all 200ms linear;
          &:hover {
            background: rgb(128, 204, 119);
          }
        }
        &.decline {
          background: #E86F79;
          transition: all 200ms linear;
          &:hover {
            background: #d25963;
          }
        }
      }
    }
  }
}

@import "_screen-exit@media";