.b-share {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;

  .jssocials-share-link {
    transition: background 200ms ease-in-out, border-color 200ms ease-in-out, transform 0.25s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }

  .fa {
    font-size: 32px;
  }
}

@media screen and (max-width: 760px) {
  .b-share {
    margin: 0;
    padding: 10px 0;
  }
}