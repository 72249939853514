// main: style.less

* {
	box-sizing: border-box;
}

html, body {
	width: 100%;
	height: 100%;
}

body {
	font-family: @basic-font;
	font-weight: @font-weight-regular;
	font-size: 14px;
	// min-width: 1340px;

	text-rendering: optimizeLegibility;
	text-decoration-skip: objects;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
}
