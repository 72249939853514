// main: style.less

@media screen and (max-width: 760px) {

	.page-error {
		&__name {
			display: block;
			text-align: center;
		}
	}

	.lingo-game {
		background: #EDEDED;
		position: relative;
		min-height: auto;
	}

	.loading-progress {
		padding: 250px 0;
		&.active {
			position: fixed;
			display: block;
		}
	}

	.playground-wrap {
		display: block;
		padding-left: 0px;
	}

	.playground-sidebar {
		display: none;
	}

	.header {
		height: 65px;
		.header-inner {
			padding: 15px;
			position: relative;
		}
		.header-wrap {
			margin: 0;
		}
		.header-item {
			position: static;

			&-logo {
				height: 65px;
				width: 65px;
				margin: -15px 0;
				img {
					height: 65px;
					width: 65px;
				}
			}
		}
		.header-item-menu {
			// top: 73px;
		}
	}

	.playground {
		padding: 0px;
	}

	.playground-inner {
		padding: 0px;
	}

	.playground-container {
		margin: 0px;
		max-width: 100%;
	}

	.playground-navbar {
		.border-radius(0px);
		.box-shadow(none);
		padding: 20px 0px;
		background: #EDEDED;
		overflow-x: scroll;
		margin: 0;
		border-bottom: 1px solid #CCCCCC;
		&-wrap {
			width: 560px;
			padding: 0;
		}
	}

	.playground-content {
		.border-radius(0px);
		.box-shadow(none);
		&-wrap {
			min-height: 0;
		}
		&-title {
			//display: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			&.b-smaller {
				line-height: 35px;
			}
		}
	}

	.mobile-player-info {
		display: block;
	}

	.mobile-player-info {
		padding: 6px 10px 23px;
		background: @blue-color;
		color: @white-color;
		.hidden {
			display: none;
		}
		.blocks {
			display: flex;
			align-items: center;
			&-item {
				margin: 0 5px;
			}
			&-right {
				width: 100%;
			}
			&-logout {
				display: block;
				.transform(translateY(-50%));
				width: 15px;
				height: 15px;
				padding: 10px;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url('/assets/icons/logout@2x.png');
				background-size: contain;
				cursor: pointer;
			}
		}
		.avatar {
			&-wrap {
				.border-radius(20px);
				border: 4px solid #FFFFFF;
				width: 76px;
				height: 76px;
				background-color: #BCBCBC;
				background-image: url(/assets/icons/no-ava.png);
				background-repeat: no-repeat;
				background-position: center;
				position: relative;
				background-size: 50px;
				overflow: hidden;
				&-img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					-o-object-fit: cover;
				}
			}
		}
		.desc {
			font-size: 12px;
			.title {
				font-size: 18px;
			}
			.country {
				margin: 5px 0;
				display: flex;
				align-items: center;
				&-flag {
					width: 20px;
					height: 14px;
					.item-flag {
						width: 100%;
						height: 100%;
						&-img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							-o-object-fit: cover;
						}
					}
				}
				&-title {
					margin: 0 10px;
				}
			}
		}
		.status {
			margin-top: 10px;
			width: 100%;
			height: 9px;
			background: #9FCDE7;
			.border-radius(40px);
			position: relative;
			&-progress {
				position: absolute;
				top: 0;
				bottom: 0;
				.border-radius(40px);
				background: #FFFFFF;
				.transition(width 200ms linear);
				.box-shadow(0 1px 2px rgba(0,0,0,0.1));
			}
		}
		.login {
			margin-top: 10px;
			width: 100%;
			a {
				display: block;
				background: #89D280;
				text-align: center;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 33px;
				text-decoration: none;
				.box-shadow(0 1px 2px rgba(0,0,0,0.25));
				.border-radius(10px);
			}
		}
	}

	.mobile-header-title {
		// height: 63px;
		padding: 19px 15px;
		background: #FDEC84;
		color: @blue-color;
		& + .header {
			display: none;
		}
		&-wrap {
			position: relative;
		}
		.heading {
			font-size: 18px;
			text-align: center;
			font-weight: @font-weight-bold;
			line-height: 25px;
		}
		.icon {
			position: absolute;
			top: 50%;
			width: 25px;
			height: 25px;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			.transform(translateY(-50%));
			&.refresh {
				background-image: url('/assets/icons/refresh-blue.png');
				left: 0;
			}
			&.close {
				background-image: url('/assets/icons/close-blue.png');
				right: 0;
			}
		}
	}

	.playground-content {
		.lingo-container {
			&.screen-register-sign-in-wrap {
				padding: 30px 15px;
			}
			&.screen-play {
				padding: 0 0 20px;
				border-radius: 0;
				&-wrap {
					border-radius: 0;
				}
			}
			&.screen-result-wrap {
				padding: 0;
			}
			&.screen-certificate-wrap {
				padding: 15px 0;
			}
			&.screen-achievements-wrap {
				padding: 15px 0;
			}
		}
	}

	.scroll-down {
		display: none;
	}

	html[dir="ltr"] {
		.header {
			.header-item-menu {
				right: 65px;
				&.active {
					transform: translateY(-40px);
				}
			}
		}
		.mobile-screen-matching {
			.matching-block {
				&-top {
					.person {
						text-align: right;
					}
				}
				&-bottom {
					.person {
						text-align: left;
					}
				}
			}
			.person {
				.country {
					&-title {
						& + .country-flag {
							margin: 0 0 0 10px;
						}
					}
					&-flag {
						& + .country-title {
							margin: 0 0 0 10px;
						}
					}
				}
			}
		}
	}
	html[dir="rtl"] {
		.header {
			.header-item-menu {
				left: 65px;
				&.active {
					transform: translateY(-40px);
				}
			}
		}
		.mobile-screen-matching {
			.matching-block {
				&-top {
					.person {
						text-align: left;
					}
				}
				&-bottom {
					.person {
						text-align: right;
					}
				}
			}
			.person {
				.country {
					&-title {
						& + .country-flag {
							margin: 0 10px 0 0;
						}
					}
					&-flag {
						& + .country-title {
							margin: 0 10px 0 0;
						}
					}
				}
			}
		}
	}

	.playground-content-title {
		.refresh {
			left: 15px;
		}
		.close {
			right: 15px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.playground {
		//padding: 15px 0 150px;
	}

	.playground-inner {
		//padding: 0px 10px;
	}

	.playground-container {
		margin: 0;
	}

	.playground-navbar {
		margin: 0 auto 15px;
	}

	.playground-sidebar .sidebar-block + .sidebar-block {
		margin-top: 15px;
	}

	.playground-sidebar.playground-sidebar-left {
		display: none;
	}

	html[dir="ltr"] {
		.playground-sidebar {
			margin: 0 0 0 15px ;
		}
	}

	html[dir="rtl"] {
		.playground-sidebar {
			margin: 0 15px 0 0;
		}
	}
}