.screen-articles {

  .article-app {
    background: #E7E7E7;

    &.closed {
      display: none;
    }
  }

  .articles {
    &-inner {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &-aside-left {
      max-width: 280px;
      min-width: 90px;
      overflow: hidden;
      // height: 100px; background: #fff; border: 2px solid red; // debug
    }

    &-aside-right {
      max-width: 280px;
      min-width: 90px;
      overflow: hidden;
      // height: 100px; background: #fff; border: 2px solid orange; // debug
    }

    &-cards {
      margin: 90px 0;

      > .wrap {
        margin: 90px auto 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        // align-content: flex-start;
        min-width: 274px;
        max-width: ((274+20)*3) * 1px;
      }

      &-title {
        display: block;
        text-align: center;
        margin-bottom: 35px;
        font-size: 36px;
        font-weight: 300;
        color: #1E252B;
        text-decoration: none;
      }
    }

    &-card {
      display: block;
      width: 274px;
      min-width: 274px;
      margin: 0 10px 20px 10px;
      color: #000;
      text-decoration: none;
      transition: transform @transition-time linear, box-shadow @transition-time linear;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }

      &-content {
        display: block;
        padding: 22px 10px 8px 10px
      }

      &-meta {
        display: block;
        margin: 14px 0 18px 0;
        color: #939EAB;
      }

      &-excerpt {
        display: block;
        color: #939EAB;
      }

      &-more {
        display: block;
        color: #5AB3E4;
        font-weight: bold;
        margin: 10px 0;
        text-align: right;

        .icon.icon-more {
          display: inline-block;
          width: 1em;
          margin-bottom: -2px;
          margin-right: 3px;
          fill: #5AB3E4;
        }
      }

      &-th {
        > span {
          display: block;
          height: 200px;
          width: 100%;
          background-size: cover;
          background-position: center;
          background-color: #5AB3E4;
        }
      }

      &-title {
        display: block;
        font-size: 23px;
        line-height: 32px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
}

@import "_screen-articles@media";