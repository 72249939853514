@media screen and (max-width: 760px) {
  .screen-lessons {
    background: #EDEDED;

    .lessons-nav {
      padding: 0 15px;

      &-items {
        justify-content: space-between;
        -webkit-justify-content: space-between;
      }

      &-item {
        margin: 0;
        width: 30%;
      }

      &-breadcrumb {
        .navs {
          display: none;
        }

        .title {
          color: #5F6D7A;
        }
      }
    }

    .lessons-block {
      padding: 0 15px;
    }

    .lessons-items {
      .items {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 48%);
        column-gap: initial;
      }

      .item {
        width: 100%;

        .content {
          display: block;
        }

        .picture {
          &-wrap {
            max-width: 100%;
            width: 132px;
            height: 132px;
            margin: auto;
          }

          img {
            min-height: 132px;
            min-width: 132px;
            max-width: 100%;
          }
        }

        .premium {
          z-index: 1;
        }
      }
    }

    .scenarios-block {
      width: 100%;

      &-wrap {
        padding: 0 15px;
      }

      .scenarios-nav-item {
        width: 45%;
      }

      .scenarios-items {
        .scenarios-border {
          background-image: url('/assets/icons/dots-vertical-blue.png');
        }

        .item {
          .title {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}