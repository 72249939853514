@media screen and (max-width: 760px) {
  .screen-matching {
    position: fixed;

    .user-wrap {
      width: auto;
    }

    .matching-blocks {
      .item {
        width: 50%;
        padding: 50px 0;
      }

      .matching-left {
        left: -100%;
        .keyframes(slideLeft; { 0% {
          left: -100%;
        } 15% {
            left: 0%;
          } 85% {
              left: 0%;
            } 100% {
                left: -100%;
              } }
        );

        &::after {
          display: none;
        }
      }

      .matching-right {
        right: -100%;
        .keyframes(slideRight; { 0% {
          right: -100%;
        } 15% {
            right: -0%;
          } 85% {
              right: -0%;
            } 100% {
                right: -100%;
              } }
        );

        &::before {
          display: none;
        }
      }
    }
  }
}