.b-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &:before {
    content: '';
    display: block;
    width: 1528px;
    height: 455px;
    margin: 20px 0;
    background-image: url('/assets/images/atlas.png');
    background-repeat: repeat-x;
    will-change: transform;
  }
}

html[dir="ltr"] {
  .b-map {
    &:before {
      .animation(map1 60s linear infinite);
    }
  }
}

html[dir="rtl"] {
  .b-map {
    &:before {
      .animation(map2 60s linear infinite);
    }
  }
}