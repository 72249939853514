.screen-language {
  .title {
    max-width: 400px;
    height: 80px;
    font-size: 36px;
    color: @white-color;
    margin: 45px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
  }
  .go-to-sign-in {
    width: 160px;
    margin: 0 auto;
    text-align: center;
    height: 60px;
    &-btn {
      display: block;
      padding: 15px 20px;
      position: relative;
      width: 100%;
      // height: 100%;
      // display: flex;
      // align-items: center;
      color: @dark-blue-color;
      .border-radius(10px);
      appearance: none;
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      // padding: 0;
      margin: 0;
      background: rgba(255, 255, 255, 0);
      &:hover {
        // background: @white-color;
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .flags {
    padding-bottom: 70px;
    .owl-stage-outer {
      padding-top: 20px;
    }
    .item {
      .transition(all 300ms linear);
      position: relative;
      bottom: 0px;
      &:hover {
        bottom: 20px;
        .item-title {
          background: @white-color;
        }
      }
      & > a {
        text-decoration: none;
        cursor: pointer;
      }
      &-image {
        position: relative;
        width: 65px;
        height: 65px;
        margin: 0 auto;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
          // position: absolute;
          // width: 65px;
          // height: 65px;
          // top: 50%;
          // left: 50%;
          // .transform(translate(-50%, -50%));
        }
      }
      &-title {
        height: 40px;
        text-align: center;
        color: @dark-blue-color;
        text-transform: uppercase;
        margin: 10px auto 0px;
        padding: 0 15px;
        .border-radius(10px);
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
      }
    }
    .owl-nav {
      span {
        display: block;
        width: 40px;
        height: 40px;
        background-color: @strong-blue-color;
        background-repeat: no-repeat;
        background-image: url('/assets/icons/slider-arrows.png');
        .border-radius(100%);
        position: absolute;
        bottom: -50px;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        &:hover {
          background-color: @white-color;
        }
      }
      .owl-prev {
        left: 30px;
        background-position: right 10px top -23px;
        &:hover {
          background-position: right 10px top 9px;
        }
      }
      .owl-next {
        right: 30px;
        background-position: right -22px top -23px;
        &:hover {
          background-position: right -22px top 9px;
        }
      }
    }
  }
}

@import "_screen-language@media";