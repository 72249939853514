@media screen and (max-width: 760px) {
  .screen-play {
    background: #EDEDED;

    .map-bg {
      display: none;
    }

    .flag-preview {
      display: none;
    }

    .lingo-statistics {
      color: #5F6D7A;
      font-size: 14px;
      font-weight: @font-weight-medium;

      .item {
        line-height: 40px;

        & + .item {
          border-top: 1px solid #CCCCCC;
        }
      }
    }

    .lets-play {
      padding: 0;

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
      }

      .button-online-play, .button-single-play {
        // display: none;
        margin-bottom: 20px;
      }

      .button-mobile-play {
        display: block;
        width: 75%;
        height: 56px;
        background: #89D280;
        border: 1px solid #89D280;
        .border-radius(20px);
        .box-shadow(0 1px 2px rgba(0, 0, 0, 0.25));

        &-btn {
          display: block;
          line-height: 54px;
          text-align: center;
          color: #FFFFFF;
          font-size: 20px;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }

    .invite-person {
      position: static;
      border: 1px solid #999999;
    }
  }
}