.screen-certificate {
  &-container {
    padding: 0 50px;
  }
  .certificate-form {
    padding: 30px 0;
    color: @white-color;
    font-size: 16px;
    &.achieved {
      padding: 15px 0;
      & + .get-certificate {
        margin: 19px auto 0;
      }
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
    .picture {
      width: 290px;
      img {
        width: 100%;
      }
    }
    .content {
      width: 290px;
    }
    .desc {
      padding: 10px 0;
    }
    .certificate-status {
      background: #A3D4EF;
      .border-radius(40px);
      margin: 5px 0;
      width: 100%;
      height: 40px;
      position: relative;
      &-label {
        position: absolute;
        width: 100%;
        line-height: 40px;
        top: 0;
        left: 0;
        padding: 0 10px;
        font-weight: @font-weight-medium;
        z-index: 9;
      }
      &-bar {
        .transition(width 200ms linear);
        background: @green-color;
        .box-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
        .border-radius(40px);
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
      }
    }
    .textfield-holder {
      margin: 10px 0;
      & + .textfield-holder {
      }
      .textfield {
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        border: none;
        width: 100%;
        line-height: 56px;
        padding: 0 20px;
        .border-radius(20px);
        &::-webkit-input-placeholder { /* Chrome */
          color: #A9AAAD;
          font-weight: 500;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #A9AAAD;
          font-weight: 500;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #A9AAAD;
          opacity: 1;
          font-weight: 500;
        }
        &:-moz-placeholder { /* Firefox 4 - 18 */
          color: #A9AAAD;
          opacity: 1;
          font-weight: 500;
        }
        &.error {
          background: rgb(255, 195, 199);
        }
      }
    }
    .grade {
      width: 140px;
      margin: 35px auto 0;
      background-repeat: no-repeat;
      background-position: top center;
      padding-top: 55px;
      text-align: center;
      &-a {
        background-image: url('/assets/icons/grade-a.png');
      }
    }
  }
  .get-certificate {
    max-width: 300px;
    width: 100%;
    margin: 14px auto 0;
    &-btn {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      display: block;
      width: 100%;
      text-align: center;
      color: @white-color;
      line-height: 56px;
      height: 56px;
      font-size: 18px;
      font-weight: @font-weight-medium;
      text-decoration: none;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
      .border-radius(20px);
      &.disabled {
        cursor: default;
        background: #CCCCCC;
      }
      &.available {
        cursor: pointer;
        background: @green-color;
        transition: all 200ms linear;
        &:hover {
          background: rgb(128, 204, 119);
        }
      }
    }
  }
}

html[dir="ltr"] {
  .screen-certificate {
    .certificate-form {
      .certificate-status-bar {
        left: 0;
      }
    }
  }
}

html[dir="rtl"] {
  .screen-certificate {
    .certificate-form {
      .certificate-status-bar {
        right: 0;
      }
    }
  }
}

@import "_screen-certificate@media";