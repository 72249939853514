@media screen and (max-width: 760px) {
  .screen-conclusion {
    .playground-content-title {
      display: block;
      // line-height: 100%;
      // font-size: 18px;
    }

    &-container {
      padding: 0 15px;
    }

    .conclusion-status {
      width: 100%;
    }

    .conclusion-actions {
      .actions {
        display: block;
      }

      .action {
        width: 100%;

        & + .action {
          margin: 12px auto 0;
        }
      }
    }

    &.single-play {
      .items {
        display: block;
      }
    }

    &.pvp-play {
      .items {
        display: block;
      }
    }

    .item {
      .content {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
      }

      .points {
        margin: 20px auto 0;
      }
    }

    .versus {
      top: 0;
      margin: 30px auto;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }
}