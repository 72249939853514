.screen-game {
  position: relative;
  // height: 100%;
  width: 100%;
  height: 495px;
  background: #EEEEEE;
  &-inner {
    height: 495px;
    // height: auto;
    // padding: 20px 16px;
    // padding: 20px;
  }
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    // height: 455px;
    .border-radius(20px);
  }
  .user-status {
    position: absolute;
    top: 0;
    bottom: 0;
    &-container {
      position: relative;
      height: 100%;
    }
    &-player {
      left: 0;
      .user-info {
        &-desc {
          &-locale {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
          }
          &-left {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
          }
        }
      }
      .user-progress {
        left: 0;
      }
    }
    &-opponent {
      right: 0;
      .user-info {
        &-desc {
          &-locale {
            justify-content: flex-end;
            -webkit-justify-content: flex-end;
          }
          &-level {
            justify-content: flex-end;
            -webkit-justify-content: flex-end;
          }
        }
      }
      .user-progress {
        right: 0;
      }
    }
  }
  .user-info {
    position: relative;
    z-index: 99;
    &-container {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
    }
    &-avatar {
      margin: 0 4px;
      &-inner {
        width: 50px;
        height: 50px;
        border: 2px solid #FFFFFF;
        background-color: #BCBCBC;
        background-image: url(/assets/icons/no-ava.png);
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center;
        .border-radius(15px);
        overflow: hidden;
      }
      img {
        display: block;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // .transform(translate(-50%, -50%));
        // max-width: 50px;
        // max-height: 50px;
        // width: auto;
        // height: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
      }
    }
    &-desc {
      margin: 0 4px;
      font-size: 10px;
      color: #5F6D7A;
      &-field {
        padding: 1px 0;
      }
      &-name {
        font-size: 12px;
        color: #000000;
      }
      &-locale {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        &-flag {
          width: 20px;
          height: 14px;
          .item-flag {
            width: 100%;
            height: 100%;
            &-img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              -o-object-fit: cover;
            }
          }
        }
        &-field {
          padding: 0 1.5px;
        }
      }
      &-level {
        display: flex;
      }
    }
  }
  .user-progress {
    position: absolute;
    top: 50px;
    bottom: 0;
    // width: 10px;
    width: 5px;
    padding: 10px 0 0;
    // margin: 0 4px;
    margin: 0 9px;
    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &-items {
      position: relative;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }
    &-item {
      margin: 0;
      width: 100%;
      position: relative;
      background: #CCCCCC;
      &:first-of-type {
        .border-radius(20px 20px 0 0);
      }
      &:last-of-type {
        .border-radius(0 0 20px 20px);
      }
      &.correct {
        &::after {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background-color: #87D37C;
          top: 0;
          left: 0;
        }
      }
      &.wrong {
        // &::after {
        // 	position: absolute;
        // 	display: block;
        // 	width: 100%;
        // 	height: 100%;
        // 	content: '';
        // 	background-color: #E86F79;
        // 	top: 0;
        // 	left: 0;
        // }
        &::after {
          position: absolute;
          display: block;
          width: 100%;
          height: 50%;
          content: '';
          background-color: #87D37C;
          top: 0;
          left: 0;
        }
        &::before {
          position: absolute;
          display: block;
          width: 100%;
          height: 50%;
          content: '';
          background-color: #E86F79;
          bottom: 0;
          left: 0;
        }
      }
      // &.partly-wrong {
      // 	&::after {
      // 		position: absolute;
      // 		display: block;
      // 		width: 100%;
      // 		height: 50%;
      // 		content: '';
      // 		background-color: #87D37C;
      // 		top: 0;
      // 		left: 0;
      // 	}
      // 	&::before {
      // 		position: absolute;
      // 		display: block;
      // 		width: 100%;
      // 		height: 50%;
      // 		content: '';
      // 		background-color: #E86F79;
      // 		bottom: 0;
      // 		left: 0;
      // 	}
      // }
    }
  }
  .user-progressline {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    z-index: 99;
    &-container {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      padding: 0 4px;
    }
    &-close {
      width: 25px;
      height: 25px;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/assets/icons/close-gray.png');
      .transition(all 200ms linear);
      &:hover {
        .transform(rotate(180deg));
      }
      &-single {
        position: absolute;
        top: 0;
        right: 4px;
      }
    }
    &-bar {
      height: 8px;
      .border-radius(20px);
      width: 100%;
      background: #CCCCCC;
      overflow-x: hidden;
      &-status {
        height: 8px;
        width: 0%;
        transition: width @slow-transition-time linear;
        background: #87D37C;
        .border-radius(20px);
        .box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
      }
    }
    &-timer {
      position: absolute;
      top: -22px;
      left: 50%;
      .transform(translateX(-50%));
      color: #5AB3E4;
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
      background-image: url('/assets/icons/timer-blue.png');
      background-repeat: no-repeat;
      background-position: left center;
      padding: 0 0 0 24px;
    }
  }
  .game-actions {
    position: absolute;
    bottom: 0;
    left: 50%;
    .transform(translateX(-50%));
    z-index: 99;
    &-items {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
    }
    &-button {
      height: 56px;
      line-height: 56px;
      color: #FFFFFF;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      .border-radius(20px);
      .box-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
      margin: 0 15px;
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      transition: background @transition-time linear,
      color @transition-time linear,
      box-shadow @transition-time linear,
      transform @transition-time linear;
      will-change: background, color, box-shadow, transform;
      &-theme-green {
        background: #89D280;
        &:hover {
          background: darken(#89D280, 10%);
        }
      }
      &-theme-red {
        background: #E86F79;
        &:hover {
          background: darken(#d25963, 10%);
        }
      }
      &-correct {
        width: 205px;
      }
      &-wrong {
        width: 205px;
      }
      &-next {
        width: 308px;
      }
      &:focus {
        position: relative;
        top: 2px;
        // .box-shadow(0 1px 2px rgba(0, 0, 0, 0.05));
      }
    }
  }
}

@import "_screen-game@media";

html[dir="rtl"] {
  .screen-game {
    .user-progressline {
      &-bar {
        margin: 0 0 0 10px;
      }
    }
  }
}
html[dir="ltr"] {
  .screen-game {
    .user-progressline {
      &-bar {
        margin: 0 10px 0 0;
      }
    }
  }
}