@media screen and (max-width: 760px) {
  .screen-statistics {
    &-container {
      padding: 0 15px;
    }

    .statistics-nav {
      &-items {
        display: grid;
        grid-template-columns: 47% 47%;
        row-gap: 20px;
      }

      &-item {
        width: 100%;
      }
    }

    .statistics-items {
      .group-items {
        grid-template-columns: 47% 47%;
        justify-content: space-between;
        column-gap: 0;
      }
    }
  }
}