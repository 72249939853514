@media screen and (max-width: 760px) {
  .screen-invite {
    &-container {
      padding: 0 10px;
    }

    .items {
      grid-template-columns: repeat(auto-fill, 170px);
    }

    .item {
      width: 170px;
    }
  }
}