.screen-notification-popup {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.65);
  z-index: 999;
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .popup {
    position: absolute;
    top: 135px;
    left: 50%;
    .transform(translateX(-50%));
    width: 330px;
    text-align: center;
    color: @white-color;
    font-size: 18px;
    font-weight: @font-weight-medium;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    background: @blue-color;
    &-wrap {
      padding: 10px;
    }
    .content {
      padding: 10px 0;
      .desc {
        padding: 15px 0;
        line-height: 125%;
        max-width: 200px;
        text-align: center;
        margin: auto;
        color: #FEED7D;
        font-weight: 700;
      }
    }
  }
}