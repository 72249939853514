.screen-tournament {
  background-position: bottom -250px center;
  background-repeat: no-repeat;
  background-image: url('/assets/icons/rays.png');
  background-size: contain;
  .tournament-title {
    text-align: center;
    color: @yellow-color;
    font-size: 34px;
    font-weight: @font-weight-medium;
    span {
      position: relative;
      display: inline-block;
      &::before, &::after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        .transform(translateY(-50%));
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/assets/icons/star-yellow.png');
      }
      &::before {
        left: -50px;
      }
      &::after {
        right: -50px;
      }
    }
    .mobile-close {
      display: none;
    }
  }
  .tournament-items {
    margin: 10px auto 0;
    .item {
      padding: 0px 25px;
      color: @white-color;
      font-size: 18px;
      height: 110px;
      position: relative;
      display: flex;
      display: -webkit-flex;
      &::before {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        content: '';
        background: rgb(252,228,125);
        background: -moz-radial-gradient(circle, rgba(252,228,125,1) 0%, rgba(252,228,125,0) 100%);
        background: -webkit-radial-gradient(circle, rgba(252,228,125,1) 0%, rgba(252,228,125,0) 100%);
        background: radial-gradient(circle, rgba(252,228,125,1) 0%, rgba(252,228,125,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fce47d",endColorstr="#fce47d",GradientType=1);
        width: 100%;
        height: 2px;
      }
      &-wrap {
        display: flex;
        align-items: center;
        padding: 0px 25px;
      }
      .icon {
        margin: 0 15px;
        width: 75px;
        text-align: center;
      }
      .info {
        margin: 0 15px;
      }
      .title {
        padding: 2px 0px;
        font-weight: @font-weight-bold;
      }
      .desc {
        font-weight: @font-weight-thin;
        line-height: 125%;
      }
    }
  }
  .tournament-participate {
    width: 300px;
    margin: auto;
    &-btn {
      display: block;
      width: 100%;
      line-height: 56px;
      height: 56px;
      .border-radius(20px);
      color: @white-color;
      text-decoration: none;
      text-align: center;
      background: #89D280;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
      font-weight: @font-weight-medium;
      font-size: 20px;
      cursor: pointer;
      transition: all 200ms linear;
      &:hover {
        background: rgb(128, 204, 119);
      }
    }
  }
}

@import "_screen-tournament@media";