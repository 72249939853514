// main: style.less
.border-radius (@value) {
    border-radius: @value;
    -o-border-radius: @value;
    -ms-border-radius: @value;
    -moz-border-radius: @value;
    -webkit-border-radius: @value;
}

.box-shadow (@params) {
    box-shadow: @params;
    -o-box-shadow: @params;
    -ms-box-shadow: @params;
    -mz-box-shadow: @params;
    -webkit-box-shadow: @params;
}

.transform (@params) {
    transform: @params;
    -o-transform: @params;
    -ms-transform: @params;
    -mz-transform: @params;
    -webkit-transform: @params;
}

.transition (@params) {
    transition: @params;
    -o-transition: @params;
    -ms-transition: @params;
    -mz-transition: @params;
    -webkit-transition: @params;
}

.animation (@params) {
    animation: @params;
    -o-animation: @params;
    -ms-animation: @params;
    -mz-animation: @params;
    -moz-animation: @params;
    -webkit-animation: @params;
}

.animation-timing-function (@value) {
    animation-timing-function: @value;
    -o-animation-timing-function: @value;
    -ms-animation-timing-function: @value;
    -mz-animation-timing-function: @value;
    -moz-animation-timing-function: @value;
    -webkit-animation-timing-function: @value;
}

.animation-fill-mode (@value) {
    animation-fill-mode: @value;
    -o-animation-fill-mode: @value;
    -ms-animation-fill-mode: @value;
    -mz-animation-fill-mode: @value;
    -moz-animation-fill-mode: @value;
    -webkit-animation-fill-mode: @value;
}

.animation-delay (@value) {
    animation-delay: @value;
    -o-animation-delay: @value;
    -ms-animation-delay: @value;
    -mz-animation-delay: @value;
    -moz-animation-delay: @value;
    -webkit-animation-delay: @value;
}

.keyframes(@name; @arguments) {
	@-moz-keyframes @name { @arguments(); }
	@-webkit-keyframes @name { @arguments(); }
	@keyframes @name { @arguments(); }
}
