.screen-article {

  .article-app-teaser {
    background: #eeeeee;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    &.fixed {
      position: fixed;
      top: 0;
    }

    &-space {
      width: 15%;
      min-width: 90px;
      max-width: 280px;
      // min-height: 10px; background: #fff; border: 2px solid red; // debug
    }
    &-title {
      font-size: 16px;
      color: #5F6D7A;
      display: flex;
      justify-content: center;
      max-width: 800px;
      width: 100%;
      margin: 0 15px;
      padding: 0 18px;
      // min-height: 10px; background: #fff; border: 2px solid orange; // debug
    }
    &-action {
      display: flex;
      justify-content: center;
      width: 30%;
      min-width: 120px;
      max-width: 280px;
      padding: 0 20px;
      // min-height: 10px; background: #fff; border: 2px solid green; // debug

      .btn {
        cursor: pointer;
        display: inline-block;
        border-radius: 20px;
        border: 1px solid #41A1D9;
        font-size: 16px;
        padding: 10px;
        text-align: center;
        margin: auto;
        color: #41A1D9;
        transition: background-color @transition-time linear;
        &:hover {
          //background: darken(#87D37B, 20%);
        }
      }
    }
  }

  .article-app {
    background: #E7E7E7;

    &.closed {
      display: none;
    }
  }

  .article-inner {
    padding-top: 70px;
    display: flex;
    justify-content: center;
  }

  .article {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 24px 15px 0 15px;
    padding: 0 18px 70px 18px;
    border-right: 1px solid #ccc;
    // min-height: 100px; background: #fff; border: 2px solid orange; // debug

    &-meta {
      color: #353535;
      font-size: 14px;
      padding-left: 5px;
      > div {
        display: inline-block;
        margin-right: 23px;
      }

      .icon.icon-time {
        display: inline-block;
        width: 1em;
        margin-bottom: -2px;
        margin-right: 3px;
      }

      .icon.icon-eye {
        display: inline;
        width: 1em;
        margin-bottom: -2px;
      }
    }

    &-view-counter {
      transition: transform @transition-time linear, color @transition-time linear;
      .icon.icon-eye {
        transition: fill @transition-time linear;
      }
      &.active {
        transform: scale(1.15);
        color: #419BD0;
        .icon.icon-eye {
          fill: #419BD0;
        }
      }
    }

    &-title {
      font-size: 20px;
      line-height: 20px;
      color: #41A1D9;
      font-weight: 400;
      margin: 18px 0 0 0;
    }

    &-subtitle {
      font-size: 40px;
      line-height: 48px;
      color: #000000;
      margin: 8px 0 8px 0;
      font-weight: 300;
    }

    &-share {
      //background: #000;
      //color: #fff;
      //height: 38px;
    }

    &-text {
      /** @see article-edit.less */
      /** @see article-view.less */

      font-size: 15px;
      line-height: 24px;
      margin: 36px 0 44px 0;
      color: #000000;

      img {
        max-width: 100%;
      }

      ul, ol {
        margin: 10px 0;
        padding: 0;
      }

      ul {
        list-style: none;

        li {
          margin-bottom: 10px;
        }

        li:before {
          content: '';
          font-weight: 600;
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #59B3E5;
          border-radius: 50%;
          margin-right: 1em;
        }
      }

      p {
        margin: 24px 0;
        padding: 0;
      }
    }

    &-app-action {
      text-align: center;
      margin-bottom: 50px;

      .btn {
        cursor: pointer;
        display: inline-block;
        background: #87D37B;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: #fff;
        transition: background-color @transition-time linear;
        &:hover {
          background: darken(#87D37B, 20%);
        }
      }
    }
  }

  .article-aside-left, .article-aside-right {
    position: relative;
    &.sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 72px;

      &:before,
      &:after {
        content: '';
        display: table;
      }
    }
  }

  .article-aside-left {
    width: 15%; // 2 / 12 * 100%;
    min-width: 90px;
    max-width: 280px;
    // min-height: 100px; background: #fff; border: 2px solid red; // debug
  }

  .article-aside-right {
    width: 30%; // 3 / 12 * 100%;
    min-width: 120px;
    max-width: 280px;
    // min-height: 100px; background: #fff; border: 2px solid green; // debug

    > .wrap {
      margin: 24px 15px 0 15px;
    }

    .article-app-action {
      text-align: center;
      margin: 20px auto;

      .btn {
        cursor: pointer;
        display: inline-block;
        background: #87D37B;
        border-radius: 20px;
        padding: 10px 20px;
        text-align: center;
        color: #fff;
        transition: background-color @transition-time linear;
        &:hover {
          background: darken(#87D37B, 20%);
        }
      }
    }
  }

  .last-articles {
    &-title {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      color: #1E252B;
      text-decoration: none;
    }
    &-cards {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 26px 0;
    }
    &-card {
      display: block;
      height: 90px;
      margin-bottom: 20px;
    }
  }

  .articles-cards {
    margin: 90px 0;

    .wrap {
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      min-width: 274px;
      max-width: ((274+20)*3) * 1px;
    }

    &-title {
      display: block;
      text-align: center;
      margin-bottom: 35px;
      font-size: 36px;
      font-weight: 300;
      color: #1E252B;
      text-decoration: none;
    }

    .articles-card {
      display: block;
      width: 274px;
      min-width: 274px;
      margin: 0 10px;
      color: #000;
      text-decoration: none;
      transition: transform @transition-time linear, box-shadow @transition-time linear;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }


      &-content {
        display: block;
        padding: 22px 10px 8px 10px
      }

      &-meta {
        display: block;
        margin: 14px 0 18px 0;
        color: #939EAB;
      }

      &-excerpt {
        display: block;
        color: #939EAB;
      }

      &-more {
        display: block;
        color: #5AB3E4;
        font-weight: bold;
        margin: 10px 0;
        text-align: right;

        .icon.icon-more {
          display: inline-block;
          width: 1em;
          margin-bottom: -2px;
          margin-right: 3px;
          fill: #5AB3E4;
        }
      }

      &-th {
        > span {
          display: block;
          height: 200px;
          width: 100%;
          background-size: cover;
          background-position: center;
          background-color: #5AB3E4;
        }
      }

      &-title {
        display: block;
        font-size: 23px;
        line-height: 32px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
}

@import "_screen-article@media";