.screen-result {
  .result-items {
    padding: 60px 0;
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 210px);
    grid-gap: 20px;
  }
  .item {
    text-align: center;
    width: 210px;
    height: 80px;
    background: @white-color;
    color: @gray-color;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    .border-radius(20px);
    &.progress {
      color: @green-color;
    }
    &-wrap {
      width: 100%;
    }
    .result {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 36px;
      img {
        height: 100%;
      }
    }
    .label {
      width: 100%;
      padding: 5px 0 0;
    }
  }
  .result-actions {
    padding: 0 30px;
    margin: 9px auto 0;
    &-btn {
      cursor: pointer;
    }
  }
  .actions {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .action {
    // appearance: none;
    // -webkit-appearance: none;
    // display: block;
    // line-height: 56px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: @gray-color;
    font-size: 20px;
    width: 200px;
    height: 56px;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    .transition(all 200ms linear);
    background: #EEEEEE;
    &:hover {
      background: #FBFBFB;
    }
    &.theme-green {
      background: #89D280;
      color: @white-color;
      &:hover {
        background: rgb(128, 204, 119);
      }
    }
  }
}

@import "_screen-result@media";