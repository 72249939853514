@keyframes scroll {
  0% { opacity: 1; }
  33% {  transform: translateY(10px); }
  100% { transform: translateY(25px) scale(0.5); opacity: 0; }
};

@keyframes map1 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-764px); }
}

@keyframes map2 {
  0% { transform: translateX(764px); }
  100% { transform: translateX(0px); }
}

@keyframes btn-pulse {
  from, to { }
  50% {
    transform: scale(1.15);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}
