.screen-invitation {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // background: rgb(12,100,149);
  // background: -moz-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // background: -webkit-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // background: linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c6495",endColorstr="#5ab3e4",GradientType=1);
  z-index: 100;
  color: @white-color;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
    background: rgba(2, 2, 2, 0.65);
    width: 100%;
    height: 100%;
  }
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  .popup {
    position: absolute;
    top: 135px;
    left: 50%;
    .transform(translateX(-50%));
    width: 330px;
    background: @blue-color;
    .border-radius(20px);
    .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    z-index: 99;
    text-align: center;
    &-wrap {
      padding: 26px 10px 10px;
    }
    .title {
      padding: 16px;
      color: @yellow-color;
      font-size: 18px;
      font-weight: @font-weight-bold;
    }
    .user {
      text-align: left;
      &-wrap {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
      }
    }
    .avatar {
      margin: 0 7px;
      &-wrap {
        width: 80px;
        height: 80px;
        background-color: #BCBCBC;
        background-repeat: no-repeat;
        background-image: url('/assets/icons/no-ava.png');
        background-position: center;
        background-size: 75%;
        .border-radius(20px);
        border: 4px solid #FFFFFF;
        position: relative;
        overflow: hidden;
        &-img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
        }
        // img {
        // 	position: absolute;
        // 	top: 50%;
        // 	left: 50%;
        // 	.transform(translate(-50%, -50%));
        // 	max-width: 100%;
        // 	max-height: 100%;
        // 	width: auto;
        // 	height: auto;
        // }
      }
    }
    .info {
      margin: 0 7px;
      font-weight: @font-weight-medium;
      .username {
        font-size: 18px;
      }
      .country {
        line-height: 18px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 7px 0;
        &-flag {
          width: 20px;
          height: 14px;
          .item-flag {
            width: 100%;
            height: 100%;
            &-img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              -o-object-fit: cover;
            }
          }
        }
        &-title {
          padding: 0 6px;
          line-height: 19px;
        }
      }
    }
    .actions {
      margin: 20px auto 0;
      &-wrap {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
      }
    }
    .button {
      display: block;
      text-align: center;
      height: 56px;
      line-height: 56px;
      .border-radius(20px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      font-size: 18px;
      font-weight: @font-weight-medium;
      cursor: pointer;
      width: 150px;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      color: #FFFFFF;
      .transition(all 200ms linear);
      &.submit {
        background: #89D280;
        &:hover {
          background: rgb(128, 204, 119);
        }
      }
      &.decline {
        background: #E86F79;
        &:hover {
          background: #d25963;
        }
      }
    }
  }
}

@import "_screen-invitation@media";