@media screen and (max-width: 760px) {
  .screen-level {
    &-wrap {
      padding: 0 30px;
    }

    .levels-items {
      margin: 35px 0;
      display: block;
    }
    .level-item {
      width: 100%;
      margin: 35px 0;
    }
    .icon {
      display: none;
    }
    .level-separater {
      width: 90%;
      height: 1px;
      margin: auto;
    }
  }
}
