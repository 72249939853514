.screen-register-sign-in {
  color: @white-color;
  text-align: center;
  font-size: 18px;
  .recover-form {
    margin: 49px auto 0;
    .recover-textfield-holder {
      margin: 50px auto 65px;
    }
    .recover-button-holder {
      margin: 50px auto 0;
    }
  }
  .reg-form {
    .reg-button-holder {
      margin: 52px auto 0;
    }
  }
  .reg-form-step-one {

  }
  .reg-form-step-two {
    display: none;
  }
  &-form {
    .textfield-holder {
      & + .textfield-holder {
        margin: 10px auto 0;
      }
    }
    .textfield {
      border: 2px  solid transparent;
      height: 55px;
      line-height: 55px;
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
      padding: 0 10px 0 28px;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      .border-radius(20px);
      &::-webkit-input-placeholder { /* Chrome */
        color: #A9AAAD;
        font-weight: 500;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #A9AAAD;
        font-weight: 500;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #A9AAAD;
        opacity: 1;
        font-weight: 500;
      }
      &:-moz-placeholder { /* Firefox 4 - 18 */
        color: #A9AAAD;
        opacity: 1;
        font-weight: 500;
      }
      &.error {
        background: rgb(255, 195, 199) !important;
        border: 2px solid rgb(255, 195, 199) !important;
        color: rgb(255, 86, 77) !important;
      }
    }
    .button-holder {
      width: 100%;
    }
    .button {
      max-width: 300px;
      width: 100%;
      line-height: 56px;
      height: 56px;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      color: @white-color;
      font-size: 20px;
      .border-radius(20px);
      &.submit {
        background: #89D280;
        transition: all 200ms linear;
        &:hover {
          background: rgb(128, 204, 119);
        }
      }
      &.link {
        background: #59B0E1;
        transition: all 200ms linear;
        &:hover {
          background: #379dd6;
        }
      }
    }
    .avatar-holder {
      width: 112px;
      height: 112px;
      background-color: @white-color;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-image: url('/assets/icons/reg-ava.png');
      position: relative;
      overflow: hidden;
      margin: 0 auto 18px;
      .border-radius(15px);
      cursor: pointer;
      &-wrap {
        // display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        overflow: hidden;
      }
      &-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
      }
      .choose-avatar-file {
        // visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
        top: 0px;
        left: 0px;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
      }
    }
  }
  .recover-pass {
    margin: 20px auto;
    &-btn {
      color: @white-color;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .auth-buttons {
    max-width: 420px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    .button-holder {
      width: 45%;
    }
  }
  .auth-social {
    max-width: 420px;
    margin: 0 auto;
    .title {
      margin: 28px auto 25px;
    }
    .items {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
    .item {
      display: block;
      width: 112px;
      height: 56px;
      .border-radius(15px);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &.facebook {
        background-color: #3E5D99;
        background-image: url('/assets/icons/facebook.png');
      }
      &.twitter {
        background-color: #3F99D2;
        background-image: url('/assets/icons/twitter.png');
      }
      &.google {
        background-color: #D04033;
        background-image: url('/assets/icons/google.png');
      }
    }
  }
  .reg-steps {
    width: 260px;
    margin: 0 auto 30px;
    &-wrap {
      background-position: center;
      background-repeat: repeat-x;
      background-image: url('/assets/icons/dots-horizontal.png');
    }
    &-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    &-item {
      display: block;
      width: 42px;
      height: 42px;
      line-height: 42px;
      color: @white-color;
      background: #A3D4EF;
      text-align: center;
      font-size: 24px;
      .border-radius(100%);
      &.active {
        background: #41A1D9;
      }
    }
  }
}

@import "_screen-register-sign-in@media";