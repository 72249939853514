.page-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	justify-content: center;
	font-size: 18px;
	&__404 {
		color: #FFFFFF;
		background: #5AB3E4;
	}
	&__name {
		display: flex;
		align-items: center;
		margin: 0 -16px;
		&--item {
			margin: 0 16px;
		}
		&--status {
			font-size: 96px;
			font-weight: 600;
		}
		&--title {
			font-size: 30px;
		}
	}
	&__desc {
		text-align: center;
		margin: 16px 0;
		color: #CCEDFF;
		line-height: 200%;
	}

	&__link {
		color: #FFFFFF;
		display: block;
		line-height: 3em;
		border: 1px solid #FFFFFF;
		border-radius: 20px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
		text-decoration: none;
		transition: color @transition-time linear, background-color @transition-time linear;
		margin: 1em auto;
		padding: 0 1em;

		&--home {
			text-align: center;
			width: 175px;
			font-weight: 600;
			text-transform: uppercase;
		}

		&:hover {
			background: #FFF;
			color: #5AB3E4;
		}
	}
}

.scroll-down {
	position: absolute;
	//bottom: -25px;
	left: 50%;
	//transform: translateX(-50%);
	&__item {
		position: absolute;
		cursor: pointer;

		&:before{
			content: '';
			display: block;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: #DADADA;
			background-image: url('/assets/icons/scroll-down-arrow.png');
			background-repeat: no-repeat;
			background-position: center;

			position: absolute;
			top: 0;
			left: -25px;
		}

		&:before {
			opacity: 0;
			animation: scroll 2s ease-out infinite;
		}
	}
}

.lingo-game {
	background: #E7E7E7;
	width: 100%;
	height: auto;
	// height: 150vh;
	min-height: 80vh;
}

.header {
	background: #419BD0;
	color: @white-color;
	height: 72px;
	.header-inner {
		max-width: 1100px;
		width: 100%;
		padding: 20px;
		margin: 0 auto;
	}
	.header-wrap {
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
		&.no-auth {
			margin: 0 auto;
		}
	}
	.header-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: -webkit-flex;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
	}
	.header-item {
		position: relative;
		&-left {
			display: flex;
			align-items: center;
			display: -webkit-flex;
			-webkit-align-items: center;
		}
		&-logo {
			height: 72px;
			width: 72px;
			margin: -20px 0;
			img {
				height: 72px;
				width: 72px;
			}
		}
		&-lang {
			font-size: 18px;
			font-weight: @font-weight-medium;
			padding: 0 18px;
			a {
				color: #fff;
				text-decoration: none;
				transition: background @transition-time linear, color @transition-time linear;
				padding: 10px 15px;
				.border-radius(10px);
				&:hover {
					background: @white-color;
					color: @strong-blue-color;
				}
			}
		}
		&-burger {
			position: relative;
		}
		&-menu {
			padding: 20px;
			font-size: 18px;
			font-weight: @font-weight-medium;
			position: absolute;
			//width: 250px;
			background: @blue-color;
			border: 1px solid rgba(255,255,255,0.5);
			.border-radius(40px);
			.box-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
			transform: translateY(-320px);
			transition: all 0.3s ease-in-out;
			z-index: 9999;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					list-style: none;
					text-align: right;
					margin-bottom: 2px;
					white-space: nowrap;
					a {
						display: block;
						text-transform: uppercase;
						color: @white-color;
						text-decoration: none;
						transition: background @transition-time linear, color @transition-time linear;
						padding: 10px 15px;
						cursor: pointer;
						.border-radius(10px);
						&:hover {
							background: @white-color;
							color: @strong-blue-color;
						}
					}
				}
			}
			&.active {
				transform: translateY(-45px);
			}
		}
	}
	.burger-component {
		cursor: pointer;
		position: relative;
		width: 25px;
		height: 18px;
		&.expanded {
			.burger-item {
				&-top {
					top: 50%;
					left: 50%;
					transform: rotate(-45deg) translate(-50%, -50%);
				}
				&-mid {
					opacity: 0;
				}
				&-bot {
					top: 50%;
					left: 50%;
					transform: rotate(45deg) translate(-50%, -50%);
				}
			}
		}
		.burger-item {
			display: block;
			width: 100%;
			height: 2px;
			left: 0px;
			position: absolute;
			background: @white-color;
			transform-origin: top left;
			transition: all @transition-time linear;
			will-change: transform, left, top, opacity;
			&-top {
				top: 0;
			}
			&-mid {
				top: 50%;
				.transform(translateY(-50%));
			}
			&-bot {
				bottom: 0;
			}
		}
	}
}

.loading-progress {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 200;
	background: rgba(255, 255, 255, 0.5);
	padding: 250px 0 50px;
	display: none;
	transition: background @transition-time linear;

	&.active {
		display: block;
	}

	&.hide {
		background-color: rgba(255, 255, 255, 0);
	}

	&-items {
		display: flex;
		align-items: center;
		display: -webkit-flex;
		-webkit-align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
	}
	.loading-item {
		display: block;
		width: 20px;
		height: 20px;
		background: #B4B4B4;
		margin: 0px 12px;
		.border-radius(100%);
		.transform(scale(0.6));
		.animation(loader 1.5s infinite);
		.animation-timing-function(ease-out);
		.animation-fill-mode(both);
		.keyframes(loader;
			{
				0% { .transform(scale(0.6)); }
				25% { .transform(scale(0.8)); }
				50% { .transform(scale(1)); }
				75% { .transform(scale(0.8)); }
				100% { .transform(scale(0.6)); }
			}
		);
		&-1 {
			.animation-delay(0s);
		}
		&-2 {
			.animation-delay(0.5s);
		}
		&-3 {
			.animation-delay(1s);
		}
	}
}

.playground {
	padding: 30px 0 30px 0;
	position: relative;
	&-inner {
		width: 100%;
		padding: 0px 20px;
		margin: 0 auto;
		//max-width: 1100px;
	}
	&-wrap {
		width: 100%;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		&::after {
			content: '';
			display: block;
			clear: both;
		}
	}
	&-container {
		position: relative;
		width: 100%;
		max-width: 720px;
		margin: 0 15px;
	}
}

.playground-navbar {
	max-width: 100%;
	width: 100%;
	background: #EEEEEE;
	padding: 14px 0 23px;
	.border-radius(20px);
	.box-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
	margin: 0 auto 20px;
	&-wrap {
		padding: 0 20px;
	}
	&-items {
		display: flex;
		align-items: center;
		display: -webkit-flex;
		-webkit-align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	&-item {
		width: 80px;
		min-width: 80px;
		display: block;
		text-decoration: none;
		color: @gray-color;
		font-size: 10px;
		text-transform: uppercase;
		position: relative;
		&:hover {
			.icon {
				transform: scale(1.15);
			}
		}
		&.active, &:hover {
			&::after {
				display: block;
				content: '';
				position: absolute;
				bottom: -12px;
				left: 50%;
				width: 6px;
				height: 6px;
				.transform(translateX(-50%));
				background: @blue-color;
				.border-radius(100%);
			}
		}
		.icon {
			display: block;
			height: 44px;
			width: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: all 200ms linear;
			&-play {
				// background-image: url('/assets/icons/navbar/play.png');
				background-image: url('/assets/icons/navbar/play@2x.png');
			}
			&-lesson {
				// background-image: url('/assets/icons/navbar/lesson.png');
				background-image: url('/assets/icons/navbar/lesson@2x.png');
			}
			&-certificate {
				// background-image: url('/assets/icons/navbar/certificate.png');
				background-image: url('/assets/icons/navbar/certificate@2x.png');
			}
			&-achievements {
				// background-image: url('/assets/icons/navbar/achievements.png');
				background-image: url('/assets/icons/navbar/achievements@2x.png');
			}
			&-statistics {
				// background-image: url('/assets/icons/navbar/statistics.png');
				background-image: url('/assets/icons/navbar/statistics@2x.png');
			}
			&-tournament {
				// background-image: url('/assets/icons/navbar/tournament.png');
				background-image: url('/assets/icons/navbar/tournament@2x.png');
			}
			&-rating {
				// background-image: url('/assets/icons/navbar/rating.png');
				background-image: url('/assets/icons/navbar/rating@2x.png');
			}
		}
		.link {
			text-align: center;
			display: block;
			margin: 7px 0 0;
		}
	}
}

.playground-sidebarnav {
	position: fixed;
	top: 25vh;
	z-index: 9999;

	&-wrap {
	}

	&-items {
	}

	&-item {
		margin-bottom: 20px;
		width: 92px;
		height: 92px;
		border: 4px solid #BCBCBC;
		background: @white-color;

		border-radius: 17px;
		display: block;
		transition: transform 200ms ease-in-out;

		.icon {
			display: block;
			height: 45px;
			width: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: transform 200ms ease-in-out;
		}

		&:hover {
			.icon {
				transform: scale(1.15);
			}
		}

		.label {
			text-align: center;
			display: block;
			margin: 7px 0 0;
			font-size: 10px;
			text-transform: uppercase;
		}
	}
}

html[dir="ltr"] {
	.playground-sidebarnav {
		left: 10px;

		&-item {
			transform: translateX(-110px);
			&.visible {
				transform: translateX(0);
			}
		}
	}
}

html[dir="rtl"] {
	.playground-sidebarnav {
		right: 10px;

		&-item {
			transform: translateX(110px);
			&.visible {
				transform: translateX(0);
			}
		}
	}
}

.playground-content {
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	.border-radius(20px);
	.box-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
	background: #5ab3e4;
	border-bottom: 1px solid #CCC;
	//background: -moz-linear-gradient(top, #5ab3e4 0%, #a3d4ef 100%);
	//background: -webkit-linear-gradient(top, #5ab3e4 0%,#a3d4ef 100%);
	//background: linear-gradient(to bottom, #5ab3e4 0%,#a3d4ef 100%);
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ab3e4', endColorstr='#a3d4ef',GradientType=0 );
	position: relative;
	&-wrap {
		position: relative;
		min-height: 495px;
		.border-radius(20px);
	}
	&-title {
		text-align: center;
		font-size: 24px;
		height: 70px;
		line-height: 70px;
		color: @blue-color;
		background: @yellow-color;
		font-weight: @font-weight-bold;
		position: relative;
		z-index: 9;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		.close {
			width: 25px;
			height: 25px;
			display: block;
			position: absolute;
			top: 50%;
			right: 40px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('/assets/icons/close-blue.png');
			cursor: pointer;
			.transform(translateY(-50%));
			.transition(all 200ms linear);
			&:hover {
				.transform(translateY(-50%) rotate(180deg));
			}
		}
		.refresh {
			width: 25px;
			height: 25px;
			display: block;
			position: absolute;
			top: 50%;
			left: 40px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('/assets/icons/refresh-blue.png');
			cursor: pointer;
			.transform(translateY(-50%) rotate(0deg));
			.transition(all 200ms linear);
			&:hover {
				.transform(translateY(-50%) rotate(360deg));
			}
		}
	}
	.lingo-container {
		padding: 30px 0px;
	}
}

.playground-sidebar {
	margin: 0 0 0 15px;
	// float: left;
	position: relative;
	// left: 30px;
	max-width: 280px;
	width: 100%;
	.sidebar-block {
		.border-radius(20px);
		overflow: hidden;
		color: @strong-gray-color;
		.box-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
		position: relative;
		& + .sidebar-block {
			margin-top: 20px;
		}
		&-title {
			text-align: center;
			font-size: 18px;
			color: @white-color;
			font-weight: @font-weight-bold;
			background: @blue-color;
			line-height: 40px;
			position: relative;
			.help {
				position: absolute;
				top: 50%;
				right: 12px;
				.transform(translateY(-50%));
				width: 19px;
				height: 19px;
				background-color: #FFFFFF;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url('/assets/icons/icon-help.png');
				cursor: pointer;
				.border-radius(50%);
			}
			&--logout {
				display: block;
				position: absolute;
				top: 50%;
				left: 12px;
				.transform(translateY(-50%));
				width: 15px;
				height: 15px;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url('/assets/icons/logout@2x.png');
				background-size: contain;
				cursor: pointer;
			}
		}
		&-content {
			padding: 16px 5px;
			background: #EEEEEE;
		}
	}
	.sidebar-profile {
		.user-info {
			// &.unauthorized {
			// 	padding-bottom: 26px;
			// }
			&-wrap {
				display: flex;
				display: -webkit-flex;
				align-items: flex-start;
				-webkit-align-items: flex-start;
			}
			.avatar {
				padding: 0 5px;
				&-wrap {
					width: 92px;
					height: 92px;
					overflow: hidden;
					border: 4px solid @white-color;
					background-color: #BCBCBC;
					background-image: url('/assets/icons/no-ava.png');
					background-repeat: no-repeat;
					background-position: center;
					.border-radius(17px);
					position: relative;
					&-img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						-o-object-fit: cover;
					}
				}
			}
			.edit-user-params {
				cursor: pointer;
			}
			.desc {
				width: 100%;
				padding: 0 5px;
				.title {
					font-size: 18px;
					padding: 5px 0px;
				}
				.country {
					display: flex;
					display: -webkit-flex;
					align-items: center;
					-webkit-align-items: center;
					font-size: 11px;
					&.hidden {
						display: none;
						visibility: hidden;
					}
					&-title {
						margin: 0 10px;
					}
					&-flag {
						width: 20px;
						height: 14px;
						.item-flag {
							width: 100%;
							height: 100%;
							&-img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								-o-object-fit: cover;
							}
						}
					}
				}
				.level {
					font-size: 11px;
					padding: 6px 0px;
				}
				.login {
					width: 100%;
					// margin-top: 25px;
					margin-top: 5px;
					margin-bottom: 5px;
					&.hidden {
						display: none;
						visibility: hidden;
					}
					a {
						display: block;
						background: #89D280;
						text-align: center;
						font-weight: @font-weight-medium;
						color: @white-color;
						line-height: 33px;
						.box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
						.border-radius(10px);
						text-decoration: none;
						transition: background @transition-time linear;
						&:hover {
							background: darken(#89D280, 10%);
						}
					}
				}
			}
		}
		.user-status {
			width: 100%;
			height: 9px;
			background: #FFFFFF;
			.border-radius(40px);
			position: relative;
			overflow: hidden;
			&-container {
				margin: 17px 0 0;
				padding: 0 5px;
			}
			&-progress {
				position: absolute;
				top: 0px;
				bottom: 0px;
				left: 0px;
				.border-radius(40px);
				background: @green-color;
				// width: 75%;
				transition: width @slow-transition-time linear;
				.box-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
			}
		}
	}
	.sidebar-progress {
		.week-days {
			&-wrap {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				display: -webkit-flex;
				-webkit-flex-wrap: wrap;
				-webkit-align-items: center;
				-webkit-justify-content: center;
				.item-day {
					display: block;
					width: 50px;
					height: 50px;
					line-height: 50px;
					background: #FFFFFF;
					color: @red-color;
					text-align: center;
					.border-radius(100%);
					margin: 5px;
					position: relative;
					overflow: hidden;
					&.achieved {
						&::after {
							content: '';
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0px;
							left: 0px;
							background-position: center;
							background-repeat: no-repeat;
							background-image: url('/assets/icons/check-mark-white.png');
							background-color: @green-color;
						}
					}
					&.not-achieved {
						&::after {
							content: '';
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0px;
							left: 0px;
							background-position: center;
							background-repeat: no-repeat;
							background-image: url('/assets/icons/close-cross-white.png');
							background-color: @red-color;
						}
					}
				}
			}
		}
		.progress-bars {
			padding: 15px 5px;
			.progress-label {
				padding: 10px 0;
			}
			.progress-status {
				width: 100%;
				height: 9px;
				background: #FFFFFF;
				.border-radius(40px);
				position: relative;
				overflow: hidden;
				&-progress {
					position: absolute;
					top: 0px;
					bottom: 0px;
					width: 0%;
					.border-radius(40px);
					background: @green-color;
					transition: width @slow-transition-time linear;
					.box-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
				}
			}
		}
		.progress-overall {
			padding: 5px 5px 0;
			&-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				display: -webkit-flex;
				-webkit-align-items: center;
				-webkit-justify-content: space-between;
			}
			&-title {

			}
			&-value {
				color: @green-color;
			}
		}
	}
	.rating-best {
		// padding: 0 5px;
		.item {
			line-height: 18px;
			& + .item {
				margin: 7px 0 0 0;
			}
			&-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
			&-left {
				display: flex;
				align-items: center;
			}
			&-count {
				padding: 0 3px;
				width: 15px;
			}
			&-flag {
				margin: 0 7px;
				height: 14px;
				width: 20px;
				&-img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					-o-object-fit: cover;
				}
			}
			&-username {
				padding: 0 7px
			}
			&-value {
				padding: 0 5px;
			}
		}
	}
	.correction-work {
		padding: 0 5px;
		.item {
			padding: 5px 0;
			&-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				display: -webkit-flex;
				-webkit-align-items: center;
				-webkit-justify-content: space-between;
			}
		}
		.correction-work-button {
			margin-top: 16px;
			&-btn {
				appearance: none;
				-webkit-appearance: none;
				border: none;
				padding: 0;
				margin: 0;
				display: block;
				text-align: center;
				color: @white-color;
				font-weight: @font-weight-medium;
				.border-radius(10px);
				width: 100%;
				line-height: 33px;
				text-decoration: none;
				cursor: pointer;
				&.disabled {
					cursor: default;
					background: #CCCCCC;
				}
				&.available {
					background: #89D280;
					transition: all 200ms linear;
					&:hover {
						background: rgb(128, 204, 119);
					}
				}
			}
		}
	}
}

html[dir="ltr"] {
	.header {
		.header-item {
			&-menu {
				right: 35px;
			}
		}
	}
	.playground-sidebar {
		.sidebar-progress {
			.progress-bars {
				.progress-status-progress {
					left: 0px;
				}
			}
		}
	}
	.playground-sidebar-left {
		margin: 0;
	}
	.playground-sidebar-right {
		margin: 0;
	}
}

html[dir="rtl"] {
	.header {
		.header-item {
			&-menu {
				left: 35px;
			}
		}
	}
	.playground-sidebar {
		.sidebar-progress {
			.progress-bars {
				.progress-status-progress {
					right: 0px;
				}
			}
		}
	}
	.playground-sidebar-left {
		margin: 0;
	}
	.playground-sidebar-right {
		margin: 0;
	}
}


.mobile-player-info {
	display: none;
}
html[dir="ltr"] {
	.mobile-player-info {
		.status {
			&-progress {
				left: 0;
			}
		}
	}
}

html[dir="rtl"] {
	.mobile-player-info {
		.status {
			&-progress {
				right: 0;
			}
		}
	}
}

