.screen-play {
  position: relative;
  border-radius: 20px;
  &-wrap {
    position: relative;
    z-index: 9;
    border-radius: 20px;
  }
  .flag-preview {
    width: 65px;
    height: 65px;
    margin: 35px auto 30px;
    text-align: center;
    .border-radius(100%);
    .box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }
  .lingo-statistics {
    max-width: 300px;
    margin: 0 auto;
    color: @white-color;
    padding: 10px 0px;
    .item {
      padding: 6px 0;
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: -webkit-flex;
        -webkit-align-items: center;
        -webkit-justify-content: space-between;
      }
    }
  }
  .lets-play {
    max-width: 300px;
    margin: 0 auto;
    padding: 40px 0 0;
    &-wrap {
      position: relative;
    }
    .button-single-play {
      width: 100%;
      height: 56px;
      background: rgba(94, 181, 228, 0.45);
      border: 1px solid @white-color;
      .border-radius(20px);
      margin-bottom: 21px;
      transition: all 200ms linear;
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
    .button-online-play {
      width: 100%;
      height: 56px;
      background: #89D280;
      border: 1px solid #89D280;
      .border-radius(20px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
      transition: all 200ms linear;
      &:hover {
        background: rgb(128, 204, 119);
      }
    }
    .button-single-play, .button-online-play {
      &-btn {
        display: block;
        line-height: 54px;
        text-align: center;
        color: @white-color;
        font-size: 20px;
        font-weight: @font-weight-medium;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .button-mobile-play {
      display: none;
    }
  }
  .invite-person {
    position: absolute;
    bottom: 0px;
    right: -76px;
    width: 56px;
    height: 56px;
    border: 1px solid @white-color;
    background-color: rgba(94, 181, 228, 0.45);
    background-image: url('/assets/icons/invite-person.png');
    background-position: center;
    background-repeat: no-repeat;
    transition: all 200ms linear;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    .border-radius(20px);
    &-btn {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

@import "_screen-play@media";