.screen-searching {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #5ab3e4;
  //background: -moz-linear-gradient(top, #5ab3e4 0%, #a3d4ef 100%);
  //background: -webkit-linear-gradient(top, #5ab3e4 0%,#a3d4ef 100%);
  //background: linear-gradient(to bottom, #5ab3e4 0%,#a3d4ef 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ab3e4', endColorstr='#a3d4ef',GradientType=0 );
  z-index: 100;
  border-radius: 20px;
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 20px;
  }
  .map-bg {
    opacity: 1;
  }
  .title {
    text-align: center;
    color: @white-color;
    text-align: center;
    font-weight: @font-weight-medium;
    font-size: 20px;
    padding-top: 30px;
    line-height: 25px;
  }
  .close {
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/assets/icons/close-white.png');
    cursor: pointer;
    top: 30px;
    right: 30px;
    transition: all 200ms linear;
    &:hover {
      .transform(rotate(180deg));
    }
  }
}

.mobile-screen-searching {
  display: none;
}

@import "_screen-searching@media";