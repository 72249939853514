.screen-matching {
  @slideTime: 4000ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  .playground-content-title {
    top: -70px;
    .animation(showTitle @slideTime + 200);
    .animation-delay(400ms);
    .animation-fill-mode(both);
    .animation-timing-function(ease-in-out);
    .keyframes(showTitle;
    {
      0% { top: -70px; }
      15% { top: 0; }
      85% { top: 0; }
      100% { top: -70px; }
    }
    );
  }
  .matching-blocks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: @white-color;
    border-radius: 20px;
    &-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 70px 0 0;
      border-radius: 20px;
      &-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    &-items {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .item {
      top: 0;
      position: absolute;
      padding: 50px 40px;
      height: 100%;
      width: 310px;
      &-content {
        position: relative;
        z-index: 99;
        width: 100%;
        height: 100%;
      }
    }

    .matching-left {
      left: -435px;
      background: @blue-color;
      .animation(slideLeft @slideTime);
      .animation-delay(750ms);
      .animation-fill-mode(both);
      .animation-timing-function(ease-in-out);
      .keyframes(slideLeft;
      {
        0% { left: -435px; }
        15% { left: 0; }
        85% { left: 0; }
        100% { left: -435px; }
      }
      );
      &::after {
        display: block;
        content: '';
        position: absolute;
        z-index: 9;
        top: 0;
        right: -100px;
        border: 100px solid transparent;
        border-left: 0 solid #5AB3E4;
        border-top: 425px solid #5AB3E4;
      }
      .user {
        top: 0;
        left: 0;
        .info {
          .country {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
          }
        }
      }
    }
    .matching-right {
      right: -435px;
      background: @green-color;
      .animation(slideRight @slideTime);
      .animation-delay(750ms);
      .animation-fill-mode(both);
      .animation-timing-function(ease-in-out);
      .keyframes(slideRight;
      {
        0% { right: -435px; }
        15% { right: 0; }
        85% { right: 0; }
        100% { right: -435px; }
      }
      );
      &::before {
        display: block;
        content: '';
        position: absolute;
        z-index: 9;
        top: -100px;
        left: -100px;
        border: 100px solid transparent;
        border-right: 0 solid #87D37C;
        border-bottom: 425px solid #87D37C;
      }
      .user {
        bottom: 0;
        right: 0;
        .info {
          .country {
            justify-content: flex-end;
            -webkit-justify-content: flex-end;
          }
        }
      }
    }
  }
  .user {
    position: absolute;
    &-wrap {
      width: 350px;
    }
    .content {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      width: 100%;
    }
    .avatar {
      padding: 0 5px;
      &-wrap {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        background-color: #BCBCBC;
        background-image: url('/assets/icons/no-ava.png');
        background-repeat: no-repeat;
        background-position: center;
        border: 4px solid @white-color;
        border-radius: 20px;
        &-img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
        }
      }
      // img {
      // 	position: absolute;
      // 	top: 50%;
      // 	left: 50%;
      // 	.transform(translate(-50%, -50%));
      // 	min-width: 100px;
      // 	min-height: 100px;
      // 	width: auto;
      // 	height: auto;
      // }
    }
    .info {
      padding: 0 5px;
      font-size: 12px;
      font-weight: @font-weight-medium;
      .username {
        font-size: 20px;
      }
      .country {
        line-height: 18px;
        display: flex;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        padding: 3px 0;
        &-title {
          padding: 0 6px;
          line-height: 19px;
        }
        &-flag {
          width: 20px;
          height: 14px;
          .item-flag {
            width: 100%;
            height: 100%;
            &-img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              -o-object-fit: cover;
            }
          }
        }
      }
      .level, .status, .username {
        padding: 3px 6px;
      }
    }
  }
  .countdown {
    width: 98px;
    height: 98px;
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
    text-align: center;
    font-weight: @font-weight-bold;
    opacity: 0;
    .animation(opacityCountDown @slideTime + 200);
    .animation-delay(400ms);
    .animation-fill-mode(both);
    .animation-timing-function(ease-in-out);
    .keyframes(opacityCountDown;
    {
      0% { opacity: 0; }
      15% { opacity: 1; }
      85% { opacity: 1; }
      100% { opacity: 0; }
    }
    );
    &-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      background: @yellow-color;
      line-height: 98px;
      .border-radius(100%);
      .box-shadow(0px 1px 4px rgba(0, 0, 0, 0.4));
      color: #5EB4E2;
      font-size: 36px;
      .transform(scale(1));
      .animation(pulseCountDown 1000ms infinite);
      .animation-delay(1500ms);
      .animation-fill-mode(both);
      .animation-timing-function(ease-in-out);
      .keyframes(pulseCountDown;
      {
        0% { .transform(scale(1)); }
        50% { .transform(scale(1.1)); }
        100% { .transform(scale(1)); }
      }
      );
      &::after {
        position: relative;
        width: 100%;
        height: 100%;
        content: 'VS';
        .animation(showCountDown 3250ms);
        .animation-delay(500ms);
        .animation-fill-mode(both);
        .animation-timing-function(ease-in-out);
        .keyframes(showCountDown;
        {
          // 0% { content: 'VS'; }
          // 25% { content: '3'; }
          // 50% { content: '2'; }
          // 75% { content: '1'; }
          // 100% { content: 'GO'; }

          0% { content: 'VS'; }
          33% { content: '3'; }
          66% { content: '2'; }
          100% { content: '1'; }
          // 100% { content: 'GO'; }
        }
        );
      }
    }
  }
}

// .mobile-screen-matching { ???
// 	display: none;
// }

html[dir="ltr"] {
  .screen-matching {
    .matching-blocks {
      .matching-left {
        .user {
          text-align: left;
          .content {
            justify-content: flex-start;
          }
        }
      }
      .matching-right {
        .user {
          text-align: right;
          .content {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .screen-matching {
    .matching-blocks {
      .matching-left {
        .user {
          text-align: right;
          .content {
            justify-content: flex-end;
          }
        }
      }
      .matching-right {
        .user {
          text-align: left;
          .content {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@import "_screen-matching@media";