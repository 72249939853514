// main: style.less

@font-face {
	font-family: 'SF-Pro-Display';
	src: url('/assets/css/fonts/SF-Pro-Display-Thin.eot');
	src: local('☺'), url('/assets/css/fonts/SF-Pro-Display-Thin.woff') format('woff'), url('/assets/css/fonts/SF-Pro-Display-Thin.ttf') format('truetype'), url('/assets/css/fonts/SF-Pro-Display-Thin.svg') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF-Pro-Display';
	src: url('/assets/css/fonts/SF-Pro-Display-Regular.eot');
	src: local('☺'), url('/assets/css/fonts/SF-Pro-Display-Regular.woff') format('woff'), url('/assets/css/fonts/SF-Pro-Display-Regular.ttf') format('truetype'), url('/assets/css/fonts/SF-Pro-Display-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF-Pro-Display';
	src: url('/assets/css/fonts/SF-Pro-Display-Medium.eot');
	src: local('☺'), url('/assets/css/fonts/SF-Pro-Display-Medium.woff') format('woff'), url('/assets/css/fonts/SF-Pro-Display-Medium.ttf') format('truetype'), url('/assets/css/fonts/SF-Pro-Display-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF-Pro-Display';
	src: url('/assets/css/fonts/SF-Pro-Display-Bold.eot');
	src: local('☺'), url('/assets/css/fonts/SF-Pro-Display-Bold.woff') format('woff'), url('/assets/css/fonts/SF-Pro-Display-Bold.ttf') format('truetype'), url('/assets/css/fonts/SF-Pro-Display-Bold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
