.screen-invite {
  min-height: 495px;
  &-container {
    padding: 0 40px;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: space-between;
    -webkit-justify-content: space-between;
    row-gap: 10px;
  }
  .item {
    width: 200px;
    display: block;
    color: @white-color;
    text-decoration: none;
    .border-radius(10px);
    cursor: pointer;
    &:hover {
      color: @dark-blue-color;
      background: @white-color;
    }
  }
  .content {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
  }
  .avatar {
    padding: 4px;
    &-wrap {
      position: relative;
      width: 50px;
      height: 50px;
      border: 2px solid @white-color;
      background-color: #BCBCBC;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('/assets/icons/no-ava.png');
      overflow: hidden;
      background-size: 30px;
      .border-radius(10px);
    }
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translate(-50%, -50%));
      // max-width: 100%;
      // max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
    }
  }
  .info {
    font-size: 10px;
    font-weight: @font-weight-medium;
    padding: 4px;
    .username {
      font-size: 12px;
    }
    .country {
      display: flex;
      align-items: center;
      display: -webkit-flex;
      -webkit-align-items: center;
      padding: 3px 0;
      &-title {
        padding: 0 6px;
      }
      &-flag {
        width: 20px;
        height: 14px;
        .item-flag {
          width: 100%;
          height: 100%;
          &-img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            -o-object-fit: cover;
          }
        }
      }
    }
  }
}

@import "_screen-invite@media";