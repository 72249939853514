.screen-level {
  .flag-preview {
    width: 65px;
    height: 65px;
    text-align: center;
    margin: 20px auto 18px;
    .border-radius(100%);
    .box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }

  .title {
    text-align: center;
    color: @white-color;
    font-size: 36px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .level-separater {
    width: 1px;
    height: 220px;
    background: #FFFFFF;
  }

  .level-item {
    width: 49%;
    display: flex;
    align-content: space-between;
    align-items: stretch;
    text-decoration: none;

    &:hover {
      .btn {
        background: @white-color;
      }
    }
  }

  .levels-items {
    margin-top: 35px;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    cursor: pointer;
  }

  .choose-level {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .btn {
      border: none;
      display: block;
      width: 175px;
      text-align: center;
      font-size: 24px;
      color: @dark-blue-color;
      padding: 15px;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
      margin: 0 auto;
      transition: background-color @transition-time linear;
    }
  }

  .icon {
    width: 100px;
    margin: 50px auto 0;
    text-align: center;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      -o-object-fit: cover;
      margin-bottom: 8px;
    }

    span {
      display: block;
      color: @dark-blue-color;
    }
  }
}

@import "_screen-level@media";