// main: style.less

@media screen and (max-width: 890px) {

	body {
		font-size: 16px;
		min-width: 100%;
		width: 100%;
	}

	.lingo-screen {
		padding: 45px 0px;
		&-items {
			display: block;
		}
		.info {
			&-wrap {
				max-width: 100%;
			}
			.content {
				line-height: 150%;
			}
		}
		.picture {
			text-align: center;
			margin-top: 25px;
			img {
				width: 100%;
			}
		}
	}

	.lingo-screen_second {
		.lingo-screen-items {
			display: flex;
			flex-direction: column-reverse;
			display: -webkit-flex;
			-webkit-flex-direction: column-reverse;
		}
	}

	.lingo-benefits {
		padding: 40px 0px;
		.content {
			padding: 40px 0px 0px;
		}
		.benefits-items {
			flex-direction: column;
			-webkit-flex-direction: column;
			align-items: flex-start;
			-webkit-align-items: flex-start;
		}
		.benefits-item {
			&-wrap {
				max-width: 100%;
			}
			&_left {
				order: 1;
				-webkit-order: 1;
			}
			&_right {
				order: 2;
				-webkit-order: 2;
				margin-top: 35px;
			}
			&_center {
				margin: 35px auto 0px;
				order: 3;
				-webkit-order: 3
			}
		}
		.benefits-item-wrap-container {
			&::after, &::before {
				display: none;
			}
		}
	}

	.promo-stores {
		padding: 60px 0px;
		.inner {
			padding: 0 10px;
		}
		&-blocks {
			display: block;
		}
		.title {
			max-width: 100%;
			text-align: center;
		}
		.content {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			display: -webkit-flex;
			-webkit-align-items: center;
			-webkit-justify-content: space-evenly;
			margin-top: 40px;
			.store-link {
				& + .store-link {
					margin: 0px;
				}
			}
		}
	}

	.lingo-block {
		padding: 0px;
		&-wrap {
			flex-direction: column;
			-webkit-flex-direction: column;
		}
		.info {
			order: 2;
			-webkit-order: 2;
			width: 100%;
			margin-top: 50px;
			.content {
				max-width: 100%;
			}
		}
		.picture {
			order: 1;
			-webkit-order: 1;
			width: 100%;
		}
	}

	.lingo-block-with_hand {
		padding: 40px 0px;
		margin-bottom: 0px;
		.picture {
			img {
				width: 100%;
			}
		}
	}

	.lingo-block-with_smartphones {
		padding-bottom: 40px;
		.picture {
			img {
				width: 100%;
			}
		}
	}

	.description-block {
		padding: 40px 0px;
	}

	.footer {
		&-top {
			&-blocks {
				display: block;
			}
			.footer-languages {
				.content {
					max-width: 100%;
				}
				.item {
					width: 100%;
				}
			}
		}
		&-bottom {
			margin-top: 40px;
			.store-links {
				justify-content: space-evenly;
				&-item {
					margin: 0 5px;
					& + .store-links-item {
						margin: 0 5px;
					}
				}
			}
		}
	}

	.copyright {
		padding: 15px 0px;
		font-size: 14px;
		&-items {
			display: block;
			line-height: 3em;
			text-align: center;
		}
		a {
			display: block;
			line-height: 3em;
		}
	}

	html[dir="ltr"] {
		.lingo-benefits {
			.benefits-item_left, .benefits-item_right {
				.item {
					text-align: left;
					padding: 0 0 0 80px;
					&::after {
						left: 0;
					}
				}
			}
		}
		.lingo-block-with_hand {
			.picture {
				img {
					left: -100px;
				}
			}
		}
		.lingo-block-with_smartphones {
			.picture {
				img {
					left: auto;
				}
			}
		}
	}

	html[dir="rtl"] {
		.lingo-benefits {
			.benefits-item_left, .benefits-item_right {
				.item {
					text-align: right;
					padding: 0 80px 0 0;
					&::after {
						right: 0;
					}
				}
			}
		}
		.lingo-block-with_hand {
			.picture {
				img {
					right: -100px;
				}
			}
		}
		.lingo-block-with_smartphones {
			.picture {
				img {
					right: auto;
				}
			}
		}
	}

}
