.screen-statistics {
  &-container {
    padding: 0 30px;
  }
  .statistics-nav {
    &-items {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      color: @white-color;
    }
    &-item {
      display: block;
      width: 150px;
      height: 40px;
      line-height: 40px;
      background: #CCCCCC;
      text-align: center;
      .border-radius(15px);
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      font-weight: @font-weight-bold;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      transition: background @transition-time linear, color @transition-time linear;
      &:hover:not(.active) {
        background: @white-color;
        color: @dark-blue-color;
      }
      &.active {
        background: @strong-blue-color;
      }
    }
  }
  .statistics-items {
    max-width: 430px;
    width: 100%;
    margin: 20px auto 0;
    .group-items {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 210px);
      row-gap: 15px;
      column-gap: 10px;
    }
    .item {
      text-align: center;
      width: 100%;
      height: 80px;
      background: @white-color;
      color: @gray-color;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-justify-content: center;
      .border-radius(20px);
      &.progress {
        color: @green-color;
      }
      &-wrap {
        width: 100%;
      }
      .result {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 36px;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: contain;
          -o-object-fit: contain;
        }
      }
      .label {
        width: 100%;
        padding: 5px 0 0;
      }
    }
  }
}

@import "_screen-statistics@media";