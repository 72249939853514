.screen-conclusion {
  color: @white-color;
  &.player-win {
    background: #5ab3e4;
    background: -moz-linear-gradient(top, #5ab3e4 0, #a3d4ef 100%);
    background: -webkit-linear-gradient(top, #5ab3e4 0, #a3d4ef 100%);
    background: linear-gradient(to bottom, #5ab3e4 0, #a3d4ef 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ab3e4', endColorstr='#a3d4ef', GradientType=0);
    .playground-content-title {
      color: @yellow-color;
      background: #439DCE;
    }
  }
  &.player-loose {
    background: rgb(12,100,149);
    background: -moz-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
    background: linear-gradient(180deg, rgba(12,100,149,1) 0%, rgba(90,179,228,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c6495",endColorstr="#5ab3e4",GradientType=1);
    .playground-content-title {
      background: @dark-blue-color;
      color: @red-color;
    }
  }
  &-container {
    padding: 0 30px;
  }
  .conclusion-players {

  }
  .items {
    display: flex;
    display: -webkit-flex;
  }
  &.single-play {
    .items {
      justify-content: center;
      -webkit-justify-content: center;
    }
    .item {
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
    }
  }
  &.pvp-play {
    display: none;
    .items {
      justify-content: center;
      -webkit-justify-content: center;
    }
    .item {
      &:first-of-type {
        .country {
          justify-content: flex-end;
        }
      }
      &:last-of-type {
        .country {
          justify-content: flex-start;
        }
      }
    }
  }
  .item {
    .content {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      width: 100%;
    }
    &.conclusion-player {
      .content {
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
      }
    }
    &.conclusion-opponent {
      .content {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
      }
    }
    .avatar {
      position: relative;
      overflow: hidden;
      width: 100px;
      height: 100px;
      background-color: #BCBCBC;
      background-image: url('/assets/icons/no-ava.png');
      background-repeat: no-repeat;
      background-position: center;
      border: 4px solid @white-color;
      .border-radius(20px);
      margin: 0 10px;
      &-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
      }
    }
    .info {
      font-size: 12px;
      font-weight: @font-weight-medium;
      margin: 0 10px;
      .username {
        font-size: 16px;
      }
      .country {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        &-flag {
          width: 20px;
          height: 14px;
          .item-flag {
            width: 100%;
            height: 100%;
            &-img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              -o-object-fit: cover;
            }
          }
        }
        &-title {
          padding: 0 6px;
          line-height: 19px;
        }
      }
      .level, .status, .username {
        padding: 3px 0;
      }
    }
    .points {
      width: 210px;
      margin: 42px auto 0;
      text-align: center;
      height: 70px;
      .border-radius(20px);
      border: 1px solid @white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      display: -webkit-flex;
      -webkit-align-items: center;
      -webkit-justify-content: center;
      span {
        width: 100%;
        display: block;
        &:first-of-type {
          font-size: 36px;
        }
        &:last-of-type {
          font-size: 14px;
        }
      }
    }
  }
  .versus {
    position: relative;
    top: 20px;
    width: 60px;
    height: 60px;
    background: @yellow-color;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: @dark-blue-color;
    font-weight: @font-weight-bold;
    .border-radius(100%);
    .box-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  }
  .conclusion-status {
    width: 530px;
    height: 20px;
    position: relative;
    background: #A3D4EF;
    .border-radius(40px);
    margin: 47px auto 0;
    &-level {
      position: absolute;
      top: -18px;
      width: 100%;
      left: 0px;
      &-wrap {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .level {
        display: block;
        font-size: 12px;
        font-weight: @font-weight-medium;
      }
    }
    &-bar {
      background: #FFFFFF;
      height: 20px;
      .border-radius(40px);
      // width: 40%;
      .transition(width 200ms linear);
    }
  }
  .conclusion-actions {
    margin: 30px auto 0;
    .actions {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
    .action {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      display: block;
      width: 265px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      background: transparent;
      text-decoration: none;
      color: @white-color;
      .border-radius(20px);
      border: 1px solid #FFFFFF;
      .transition(all 200ms linear);
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
      &.theme-green {
        background: #89D280;
        border: 1px solid #89D280;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
        &:hover {
          background: rgb(128, 204, 119);
        }
      }
    }
  }
}

html[dir="ltr"] {
  .screen-conclusion {
    &.pvp-play {
      .item {
        &.conclusion-player {
          text-align: right;
          // .content {
          // 	justify-content: flex-end;
          // }
        }
        &.conclusion-opponent {
          text-align: left;
          // .content {
          // 	justify-content: flex-start;
          // }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .screen-conclusion {
    &.pvp-play {
      .item {
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
}

@import "_screen-conclusion@media";