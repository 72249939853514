.screen-answer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: 20px;
  display: none;
  &.correct {
    background-color: @green-color;
    .icon {
      background-image: url('/assets/icons/answer-correct.png');
      background-color: @strong-green-color;
    }
  }
  &.incorrect {
    background-color: #E86F79;
    .icon {
      background-image: url('/assets/icons/answer-incorrect.png');
      background-color: @strong-red-color;
    }
  }
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.compact .screen-answer {
  border-radius: 0;
}

@import "_screen-answer@media";