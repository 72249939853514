@media screen and (max-width: 760px) {
  .screen-result {
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, 45%);
      row-gap: 20px;
      justify-content: space-between;
    }

    .item {
      width: 100%;
    }

    .result-items {
      padding: 15px;
    }

    .result-actions {
      padding: 15px;
      margin: 0;
      background: #EEEEEE;
    }

    .actions {
      display: block;
    }

    .action {
      width: 100%;

      &:not(.theme-green) {
        border: 1px solid #999999;
      }

      & + .action {
        margin: 12px 0 0 0;
      }
    }
  }
}