@media screen and (max-width: 760px) {
  .screen-exit {
    position: fixed;

    .popup {
      width: 90%;

      .content {
        .actions {
          display: block;
        }

        .action {
          width: 100%;

          & + .action {
            margin: 10px auto 0;
          }
        }

      }
    }
  }
}