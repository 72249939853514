.screen-achievements {
  font-size: 16px;
  .achievements-items {
    &-wrap {
      padding: 10px 85px 70px 85px;
    }
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, 100px);
      row-gap: 70px;
      column-gap: 50px;
    }
    .item {
      width: 100px;
      height: 100px;
      background-color: @white-color;
      .border-radius(100%);
      border: 2px solid @white-color;
      position: relative;
      transition: border @transition-time @transition-time ease-in-out;
      .icon {
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.2;
        transition: transform @transition-time ease-in-out;
        &.trophy {
          background-image: url('/assets/icons/achievements/trophy.png');
        }
        &.thumbs-up {
          background-image: url('/assets/icons/achievements/thumbs-up.png');
        }
        &.bronze-medal {
          background-image: url('/assets/icons/achievements/bronze-medal.png');
        }
        &.gold-medal {
          background-image: url('/assets/icons/achievements/gold-medal.png');
        }
        &.favorite {
          background-image: url('/assets/icons/achievements/favorite.png');
        }
        &.rating {
          background-image: url('/assets/icons/achievements/rating.png');
        }
        &.silver-medal {
          background-image: url('/assets/icons/achievements/silver-medal.png');
        }
        &.certificate {
          background-image: url('/assets/icons/achievements/certificate.png');
        }
      }
      .label {
        margin-top: 10px;
        text-align: center;
        color: #6C7A88;
        transition: color @transition-time ease-in-out;
      }
      &:hover, &.active {
        border-color: #FFD03D;
        .label {
          color: @white-color;
        }
        .icon {
          opacity: 0.6;
          transform: scale(1.15);
        }
      }
      &.achieved {
        .icon {
          opacity: 1;
        }
        .label {
          color: @white-color;
        }
      }

      .counter {
        display: block;
        width: 27px;
        height: 27px;
        line-height: 27px;
        color: @dark-blue-color;
        text-align: center;
        .border-radius(100%);
        font-size: 12px;
        font-weight: @font-weight-bold;
        background-color: #FFDD00;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
  .achievements-desc {
    color: @white-color;
    text-align: center;
    max-width: 380px;
    width: 100%;
    margin: 20px auto;
  }
  .get-certificate {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    &-btn {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      .border-radius(20px);
      display: block;
      line-height: 56px;
      text-align: center;
      width: 100%;
      height: 56px;
      color: @white-color;
      font-size: 18px;
      font-weight: @font-weight-medium;
      text-decoration: none;
      .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
      &.disabled {
        cursor: default;
        background: #CCCCCC;
      }
      &.available {
        cursor: pointer;
        background: #89D280;
        transition: all 200ms linear;
        &:hover {
          background: rgb(128, 204, 119);
        }
      }
    }
  }
}

@import "_screen-achievements@media";
