.b-game {
  position: relative;
  height: 100%;
  padding: 20px 16px;
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .b-ear-placeholder, .b-eye-placeholder {
    display: none;
  }
}

.b-task {
  position: relative;
  width: 100%;
  height: 100%;
  &-full-bottom {
    .b-task {
      &-inner {
        padding-bottom: 0;
      }
    }
  }
  &-full-width {
    .b-task {
      &-inner {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    // padding: 15px 40px 15px;
    // padding: 15px 60px 0px;
    // padding: 30px 60px 0px;
    padding: 40px 60px 0px;

    &.smart {
      padding: 0;
      .user-progressline {
        position: relative;
        margin-top: 75px;
      }
    }
  }
  &-container {
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 760px) {
  .b-task {
    &-inner {
      padding: 80px 20px 0;
    }
  }

  .b-game {
    overflow-x: hidden;
  }
}

.b-ux-item {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  // cursor: pointer;
  margin: 5px;
  .box-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
  &-textalign_left {
    text-align: left;
  }
  &-margin_center {
    margin-left: auto;
    margin-right: auto;
  }
  &-round {
    .border-radius(50%);
  }
  &-border-15 {
    .border-radius(15px);
  }
  &-border-20 {
    .border-radius(20px);
  }
  &-theme {
    &-white {
      background-color: #FFFFFF;
      color: #5F6D7A;
    }
    &-blue {
      background-color: #41A1D9;
      color: #FFFFFF;
    }
    &-green {
      background-color: #87D37C;
      color: #FFFFFF;
    }
    &-transparent {
      background-color: transparent;
      color: #5F6D7A;
      .box-shadow(none);
    }
  }
}

.b-ear {
  width: 50px;
  height: 50px;
  background-image: url('/assets/icons/ear-gray.png');
  cursor: pointer;

  will-change: box-shadow, transform;
  &:active {
    animation: btn-pulse 0.7s ease;
  }
}

.b-eye {
  width: 50px;
  height: 50px;
  background-image: url('/assets/icons/eye-gray.png');
  cursor: pointer;

  will-change: box-shadow, transform;
  &:active {
    animation: btn-pulse 0.7s ease;
  }
}

.b-star {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icons/star-gray.png');
  cursor: pointer;
}

.b-close {
  width: 12px;
  height: 12px;
  background-image: url('/assets/icons/close-gray.png');
  cursor: pointer;
}

.b-question {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icons/question-green.png');
  cursor: pointer;

  will-change: box-shadow, transform;
  &:active {
    animation: btn-pulse 0.7s ease;
  }
}

.b-help {
  width: 42px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  transition: background @transition-time linear;
  &:hover {
    background: darken(#89D280, 10%);
  }
}

.b-backspace {
  width: 42px;
  height: 42px;
  background-image: url('/assets/icons/backspace-blue.png');
  cursor: pointer;
}

.b-slide-nav {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  .transform(translateY(-50%));
  &-prev {
    left: 0;
    background-image: url('/assets/icons/slide-prev-gray.png');
  }
  &-next {
    right: 0;
    background-image: url('/assets/icons/slide-next-gray.png');
  }
}

.b-slide-close {
  position: absolute;
  top: 0;
  right: 0;
}

.b-sound {
  width: 120px;
  height: 100px;
  background-image: url('/assets/icons/sound-blue.png');
  cursor: pointer;
}

.b-letter {
  width: 42px;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  .transition(all 200ms linear);
  &:hover {
    background: #379dd6;
  }
  &.used {
    // display: none;
    visibility: hidden;
  }
  &[data-qty] {
    &::after {
      display: block;
      content: attr(data-qty);
      color: #FEED7D;
      font-size: 10px;
      position: absolute;
      top: 6px;
      right: 6px;
      line-height: 100%;
    }
  }
}

.b-word {
  font-size: 24px;
  padding: 0 20px;
  // line-height: 56px;
  height: 46px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  &.active {
    border: 2px solid #5AB3E4;
    line-height: 52px;
  }
  &-small {
    line-height: 42px;
    &.active {
      line-height: 38px;
    }
  }
  &-smaller {
    font-size: 20px;
  }
  &-selectable {
    cursor: pointer;
  }
  &[data-transcription] {
    // margin-bottom: 20px;
    display: block;
    margin-bottom: 35px;
    &::after {
      display: block;
      content: attr(data-transcription);
      line-height: 100%;
      font-size: 18px;
      position: absolute;
      //bottom: -4px;
      left: 50%;
      .transform(translateX(-50%));
    }
  }
  // &[data-qty] {
  // 	&::after {
  // 		display: block;
  // 		content: attr(data-qty);
  // 		color: #FEED7D;
  // 		font-size: 10px;
  // 		position: absolute;
  // 		top: 6px;
  // 		right: 6px;
  // 		line-height: 100%;
  // 	}
  // }
  &-helping {
    cursor: pointer;
  }
}

.b-phrase {
  font-size: 18px;
  line-height: 42px;
  padding: 0 16px;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  &.used {
    // display: none;
    background: #C0C0C0;
    pointer-events: none;
  }
  &[data-qty] {
    &::after {
      display: block;
      content: attr(data-qty);
      color: #FEED7D;
      font-size: 10px;
      position: absolute;
      top: 6px;
      right: 6px;
      line-height: 100%;
    }
  }
  &-help {
    padding: 0 10px;
    color: #CCCCCC;
    pointer-events: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
  &-user-container {
    .b-phrase {
      &::after {
        display: none;
      }
    }
  }
}

.b-cardpreview {
  // overflow: hidden;
  &-size-l {
    width: 250px;
    height: 250px;
  }
  &-size-m {
    width: 220px;
    height: 220px;
  }
  &-size-s {
    width: 140px;
    height: 140px;
  }
  &-size-xs {
    width: 110px;
    height: 110px;
  }
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
  }
  &-pic {
    display: block;
    width: 100%;
    .border-radius(10px);
    &-selectable {
      cursor: pointer;
    }
  }
  &-text {
    display: block;
  }
  .b-ux-group {
    position: absolute;
    top: 50%;
    .transform(translateY(-50%));
    &-one {
      right: -75px;
    }
    &-two {
      right: -135px;
    }
    .b-ux-item {
      & + .b-ux-item {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .b-cardpreview {
    .b-ux-group-two, .b-ux-group-one {
      display: none;
    }
  }
}

.b-ux-group {
  display: flex;
  align-items: center;
  // .b-ux-item {
  // 	& + .b-ux-item {
  // 		margin-left: 10px;
  // 	}
  // }
}

.b-input {
  cursor: default;
  &-text {
    padding: 0 13px;
    line-height: 42px;
    font-size: 18px;
    height: 42px;
    &-help {
      color: #CCCCCC;
      pointer-events: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
  }
}

.b-ux-field {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  position: relative;
  height: 105px;
  margin: 5px;
  &-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #CCCCCC;
  }
}

.b-ux-section {
  margin: 0 auto;
  width: 100%;
  &-300 {
    width: 300px;
  }
  &-600 {
    width: 600px;
  }
}

.tournament-activated, .testing-activated {
  .b-ear, .b-question, .b-help-letter,
  .b-eye, .b-help-phrase {
    display: none;
  }
}

.tournament-activated, .testing-activated {
  .b-ear-placeholder {
    height: 50px;
    display: block;
    margin: 5px 0;
  }
  .b-eye-placeholder {
    height: 50px;
    display: block;
    margin: 5px 0;
  }
}


.b-ux-wrap {

}

.b-slide-card {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  transform-origin: 50% 150%;
  transition: transform 0.6s 0.2s ease-in-out;
  transform: translateX(120%);
  will-change: transform;

  &.active {
    transform: translateX(0);
  }
  &.hidden {
    transform: translateX(-120%) rotateZ(-15deg);

    .game-actions-button {
      transform: scale(1.15);
      .box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
    }
  }
  &-choose {
    padding-top: 15px;
  }
  &-writing {
    padding-top: 25px;
  }
  &-audio {
    padding-top: 20px;
  }
}

.b-slide-word {
  &-opened, &-true-false {

    // display: flex;
    // display: -webkit-flex;

    display: none;
    &.active {
      display: flex;
      display: -webkit-flex;
    }

    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    justify-content: center;
    -webkit-justify-content: center;
  }
  &-choose-translation {
    &-native, &-learning {

      // display: flex;
      // display: -webkit-flex;

      display: none;
      &.active {
        display: flex;
        display: -webkit-flex;
      }

      padding-top: 45px;
      margin: 0 auto;
      max-width: 500px;
      position: relative;
      width: 100%;
      height: 100%;
      flex-direction: column;
      -webkit-flex-direction: column;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
  }
  &-writing-learning, &-audio-write {

    // display: flex;
    // display: -webkit-flex;

    display: none;
    &.active {
      display: flex;
      display: -webkit-flex;
    }

    margin: 0 auto;
    max-width: 500px;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  &-audio-choose {
    display: none;
    &.active {
      display: block;
    }
    margin: 0 auto;
    max-width: 500px;
  }
}

.b-slide-phrase {
  &-opened, &-true-false {

    // display: flex;
    // display: -webkit-flex;

    display: none;
    &.active {
      display: flex;
      display: -webkit-flex;
    }

    max-width: 500px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    justify-content: center;
    -webkit-justify-content: center;
  }
  &-choose-translation {
    &-native, &-learning {

      // display: flex;
      // display: -webkit-flex;

      display: none;
      &.active {
        display: flex;
        display: -webkit-flex;
      }

      padding-top: 45px;
      margin: 0 auto;
      max-width: 500px;
      position: relative;
      width: 100%;
      height: 100%;
      flex-direction: column;
      -webkit-flex-direction: column;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
  }
  &-translate  {

    // display: flex;
    // display: -webkit-flex;

    display: none;
    &.active {
      display: flex;
      display: -webkit-flex;
    }

    margin: 0 auto;
    max-width: 500px;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  &-audio {
    &-write, &-choose {

      // display: flex;
      // display: -webkit-flex;

      display: none;
      &.active {
        display: flex;
        display: -webkit-flex;
      }

      margin: 0 auto;
      max-width: 500px;
      position: relative;
      width: 100%;
      height: 100%;
      flex-direction: column;
      -webkit-flex-direction: column;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
    }
  }
}

.learning-word {
  display: block;
  &.helping {
    display: none;
  }
  // & + .native-word {
  // 	display: none;
  // }
  // &-need-help {
  // 	display: none;
  // 	& + .native-word {
  // 		display: flex;
  // 	}
  // }
}

.native-word {
  display: none;
  &.helping {
    display: block;
  }
}

.learning-word, .native-word {
  .transcription {
    display: block;
    line-height: 100%;
    font-size: 18px;
  }
}