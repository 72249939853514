.screen-premium {
  .mobile-close {
    display: none;
  }
  &-wrap {
    &-info {
      background-repeat: no-repeat;
      background-image: url('/assets/icons/rays.png');
      background-size: contain;
      background-position: right top -35px;
    }
  }

  &-banner {
    text-align: center;
    padding: 0 15px;
    img {
      max-width: 100%;
    }
  }

  &-title {
    color: @white-color;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-top: 24px;
  }

  .premium-info {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    .desc {
      width: 50%;
      &-wrap {
        max-width: 230px;
        margin: 0 auto;
      }

      .benefits {
        padding: 20px 0;

        .item {
          color: @white-color;
          font-size: 18px;
          line-height: 21px;
          padding: 4px 0;
          &-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          &-icon {
            width: 18px;
            height: 18px;
            background: url('/assets/icons/e-check.png') no-repeat center;
          }
          &-title {
            padding: 0 4px;
          }
        }
      }
      .qr-code {
        text-align: center;
      }
    }
    .variants {
      width: 50%;
      &-wrap {
        max-width: 305px;
        width: 100%;
      }
      .items {
        padding: 0 15px 0 0;
      }
      .item {
        padding: 5px 7px 5px 20px;
        background: #F2F2F2;
        .border-radius(20px);
        .box-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
        height: 72px;
        text-decoration: none;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        & + .item {
          margin-top: 15px;
        }
        &:nth-child(2) {
           .content {
             .info {
               .title {
                 color: #FFA500;
               }
             }

             .price {
               background: #FFA500;
             }
           }
        }
        &-premium-tariff {
          cursor: pointer;
        }
        .content {
          width: 100%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: space-between;
          -webkit-justify-content: space-between;

          .info {
            .title {
              color: @strong-blue-color;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
            .qty {
              font-weight: @font-weight-bold;
              font-size: 18px;
              color: #596676;
            }
            .cost {
              font-weight: @font-weight-medium;
              font-size: 14px;
              line-height: 17px;
              color: #3491C3;
            }
          }

          .price {
            background: @green-color;
            color: @white-color;
            text-align: center;
            width: 50%;
            max-width: 140px;
            padding: 15px 0;
            .border-radius(17px);
            font-size: 12px;
          }
        }
      }
      .app-links {
        // margin-top: 30px;
        &-wrap {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: space-between;
          -webkit-justify-content: space-between;
        }
        a {
          display: block;
          .transition(all 200ms linear);
          &:hover {
            .transform(scale(1.025));
          }
        }
      }
    }
  }
  .premium-form {
    text-align: center;
    color: @white-color;
    &-wrap {
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
    }
    .desc {
      color: @yellow-color;
      font-size: 20px;
      padding: 5px 0;
    }
    .info {
      padding: 7px 0;
      line-height: 20px;
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .card-fields {
      padding: 15px 0 20px;
      &-top {
        width: 100%;
        margin: 0 auto 15px;
      }
      &-bot {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        .textfield {
          max-width: 180px;
        }
      }
    }
    .card-field-holder {
      text-align: left;
      .label {
        font-size: 16px;
        font-weight: @font-weight-medium;
        padding: 5px 0;
      }
    }
    .textfield {
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      .border-radius(22px);
      background-color: #FFFFFF;
      background-repeat: no-repeat;
      background-image: url('/assets/icons/card-elements.png');
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      color: #999999;
      border: none;
      padding: 0 18px 0 58px;
      width: 100%;
      &.cardnum {
        background-position: left 20px top 13px;
      }
      &.valid-thru {
        background-position: left 20px top -57px;
      }
      &.cvv {
        background-position: left 20px top -126px;
      }
      &::-webkit-input-placeholder { /* Chrome */
        color: #999999;
        font-weight: @font-weight-medium;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #999999;
        font-weight: @font-weight-medium;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #999999;
        opacity: 1;
        font-weight: @font-weight-medium;
      }
      :-moz-placeholder { /* Firefox 4 - 18 */
        color: #999999;
        opacity: 1;
        font-weight: @font-weight-medium;
      }
    }
    .card-pay {
      margin: 20px auto 0;
      .button {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        outline: none;
        height: 56px;
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
        background: @green-color;
        color: @white-color;
        max-width: 308px;
        width: 100%;
        text-align: center;
        .border-radius(20px);
        cursor: pointer;
        .transition(all 200ms linear);
        &:hover {
          background: rgb(128, 204, 119);
        }
        span {
          display: block;
          font-size: 18px;
        }
      }
    }
  }

  .app-links {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    gap: 10px;

    &-item {
      height: 44px;
      transition: top 100ms linear;
      background-position: center;
      background-repeat: no-repeat;
      width: 160px;
      background-size: cover;
      border-radius: 10px;

      &.app-store {
        background-image: url('/assets/images/app-store-logo.svg');
      }

      &.google-play {
        background-image: url('/assets/images/google-play-logo.svg');
      }
    }
  }
}

html[dir="ltr"] {
  .screen-premium-wrap-info {
    background-position: right top -35px;
  }
}

html[dir="rtl"] {
  .screen-premium-wrap-info {
    background-position: left top -35px;
  }
}

@import "_screen-premium@media";

.screen-premium.screen-premium-vk {
  .screen-premium-wrap-info {
    background: transparent;
  }

  .rays {
    width: 400px;
    height: 400px;
    position: relative;
    margin-right: 20px;
    margin-top: -40px;

    &:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-image: url(/assets/icons/rays.png);
      background-size: contain;
      background-position: center center;
      transform-origin: 50% 50%;
      width: 400px;
      height: 400px;
      animation: spin 60s linear;
    }
  }
  .qr-code {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -50px;
    margin-top: -50px;
    width: 100px;
    height: 100px;
  }

  .app-links {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 60px;

      &-item {
        display: block;
        width: 180px;
        height: 50px;
        margin: 5px;
        background-size: cover;
        border-radius: 10px;
        background-color: @white-color;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        top: 0;
        transition: top 100ms linear;
        &:hover {
          top: -2px;
        }
        & + .store-links-item {
          margin: 0 20px;
        }
        &.app-store {
          background-image: url('/assets/images/app-store-logo-white.svg');
        }
        &.google-play {
          background-image: url('/assets/images/google-play-logo.svg');
          border: 1px solid #fff;
          border-radius: 10px;
        }
      }
  }
}