@media screen and (max-width: 760px) {
  .screen-premium {
    position: relative;

    &-wrap {
      &-info {
        background-size: cover;
      }
    }

    .mobile-close {
      display: block;
      width: 25px;
      height: 25px;
      position: absolute;
      background: url('/assets/icons/close-white.png') no-repeat center;
      top: 20px;
      right: 15px;
    }

    .app-links {
      display: flex;
    }

    .premium-info {
      display: block;
      padding: 0 15px;

      .desc {
        width: 100%;

        &-wrap {
          max-width: 100%;
        }

        .qr-code {
          display: none;
        }
      }

      .variants {
        width: 100%;

        &-wrap {
          max-width: 100%;
          .items {
            padding: 0 15px;
          }

          .item {
            .content {
              .info {
                .title {
                  display: none;
                }

                > .qty {
                  font-weight: @font-weight-medium;
                  line-height: 24px;
                }

                .cost {
                  font-weight: @font-weight-regular;
                }
              }

              > .price {
                background: @green-color !important;
                width: 170px;
              }
            }
          }
        }

        .app-links {
          &-wrap {
            justify-content: space-evenly;
          }
        }
      }
    }

    .premium-form {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 760px) {
  html[dir="ltr"] {
    .screen-premium {
      &-wrap {
        &-info {
          background-position: bottom -330px center;
        }
      }
    }
  }

  html[dir="rtl"] {
    .screen-premium {
      &-wrap {
        &-info {
          background-position: bottom -330px center;
        }
      }
    }
  }
}