@media screen and (max-width: 760px) {
  .screen-achievements {
    .achievements-desc {
      padding: 0 40px;
    }

    .achievements-items {
      &-wrap {
        padding: 0 15px;
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }

      .item {
        margin: 10px 0 70px 0;
      }
    }
  }
}