@media screen and (max-width: 760px) {
  .screen-rating {
    &-wrap {
      padding: 0 15px;
    }

    .rating-nav {
      &-items {
        display: grid;
        grid-template-columns: 47% 47%;
        row-gap: 10px;
      }

      &-item {
        width: 100%;
      }
    }

    .rating-list {
      padding: 10px 0 0;

      &-table {
        tr {
          td {
            font-size: 14px;

            &:nth-child(4), &:nth-child(5) {
              display: none;
            }
          }
        }
      }
    }
  }
}