@media screen and (min-width: 760px) {
  .screen-article {
    .article-app {
      .playground {
        padding-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .screen-article {
    .article-app-teaser {
      &-space {
        display: none;
      }

      &-title {
        width: 70%;
        margin: auto;
        padding: 10px;
      }

      &-action {
        width: 30%;
        margin: auto;
        padding: 10px;
      }
    }

    .article-inner {
      .article {
        width: 70%;
      }

      .article-aside-left {
        display: none;
      }

      .article-aside-right {
        width: 30%;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .screen-article {

    .article-app-teaser {
      display: block;
      height: 60px;
      overflow: hidden;

      &-title {
        display: none;
      }

      &-action {
        width: 100%;
        margin: auto;
        padding: 10px;
      }
    }

    .article-app {
      .playground-content {
        margin-top: -15px;
      }
    }

    .article-inner {
      padding-top: 60px;
      display: block;

      .article-aside-left {
        display: none;
      }

      .article-aside-right {
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 20px auto 0 auto;
      }

      .article {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 0;
        order: 2;
      }
    }
  }
}