.smartbanner {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 60px;
  background: #FEED7D;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    max-width: 80vw;
  }

  &-show {
    margin-top: 60px;
  }

  &-show .smartbanner {
    display: block;
  }

  &-container {
    margin: 0 auto;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 16px;
  }

  &-icon {
    width: 38px;
    height: 38px;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    color: #1E252B;
  }

  &-subtitle {
    font-size: 18px;
    line-height: 22px;
    color: #1E252B;
  }

  &-button {
    width: 73px;
    height: 31px;
    background: #5AB3E4;
    border-radius: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFDFF;
    padding: 6px 18px;
    text-decoration: none;
  }
}

@media screen and (max-width: 760px) {
  .smartbanner {
    height: 54px;

    &-show {
      margin-top: 54px;
    }

    &-subtitle {
      display: none;
    }
  }
}
