@media screen and (max-width: 760px) {
  .screen-game {
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &-inner {
      height: 100vh;
    }

    .game-actions {
      position: relative;
      bottom: 0;
      left: 0;
      .transform(none);
      margin-top: 20px;

      &-items {
        flex-direction: column;
      }

      .game-actions-button {
        &-correct, &-wrong, &-next {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }
}