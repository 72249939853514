@media screen and (max-width: 760px) {
  .screen-certificate {
    &-container {
      padding: 0 15px;
    }

    .certificate-form {
      padding: 0;

      &-wrap {
        display: block;
      }

      .picture {
        margin: auto;
      }

      .content {
        width: 100%;
        padding: 10px 0 0;
      }

      .grade {
        margin: 25px auto;
      }

      &.achieved {
        // & + .get-certificate {
        // 	margin: 0 auto;
        // }
      }
    }

    // .get-certificate {
    // 	margin: 0 auto;
    // }
  }
}